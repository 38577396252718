import { QueryHookOptions, useQuery } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { NextPage } from 'next';
import { NextRouter, useRouter } from 'next/router';
import type React from 'react';

import { getApolloClient, ApolloClientContext } from '../Apollo';
import * as Operations from './graphql';

export type Maybe<T> = T | undefined;
export type InputMaybe<T> = T | undefined;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Time: any;
  UUID: any;
  Upload: any;
};

export type Account = {
  __typename?: 'Account';
  acceptsMarketing: Scalars['Boolean'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['UUID'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
  role: Role;
};

export type AccountInput = {
  acceptsMarketing: Scalars['Boolean'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
};

export type AddElementMessage = {
  __typename?: 'AddElementMessage';
  id: Scalars['UUID'];
  spreadID: Scalars['UUID'];
};

export type AddSpreadMessage = {
  __typename?: 'AddSpreadMessage';
  destinationIndex: Scalars['Int'];
  id: Scalars['UUID'];
};

export type Address = {
  __typename?: 'Address';
  address: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  /** Shopify id. */
  id: Scalars['String'];
  zip: Scalars['String'];
};

export type AddressInput = {
  address: Scalars['String'];
  city: Scalars['String'];
  id: Scalars['String'];
  zip: Scalars['String'];
};

export type AdminImageReorderInput = {
  destinationIndex: Scalars['Int'];
  sourceIndex: Scalars['Int'];
};

export type AdminImageUpdateInput = {
  id: Scalars['UUID'];
  status: ImageStatus;
};

export type Author = {
  __typename?: 'Author';
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type Book = {
  __typename?: 'Book';
  id: Scalars['UUID'];
  isDeletable: Scalars['Boolean'];
  isTemplate: Scalars['Boolean'];
  memberRole?: Maybe<MemberRole>;
  memberStatus?: Maybe<MemberStatus>;
  modifiedAt: Scalars['Time'];
  owner?: Maybe<Account>;
  spine: Spine;
  spreads: Array<BookSpread>;
  title: Scalars['String'];
  viewableSpreads: Scalars['Boolean'];
};

export enum BookCover {
  Glossy = 'GLOSSY',
  Matte = 'MATTE',
}

export type BookElementContainer = {
  __typename?: 'BookElementContainer';
  createdAt: Scalars['Time'];
  height: Scalars['Float'];
  id: Scalars['UUID'];
  image: ElementImage;
  imageHeight: Scalars['Float'];
  imageLeft: Scalars['Float'];
  imageTop: Scalars['Float'];
  imageWidth: Scalars['Float'];
  left: Scalars['Float'];
  rotation: Scalars['Float'];
  round: Scalars['Boolean'];
  top: Scalars['Float'];
  type?: Maybe<ElementType>;
  updatedAt: Scalars['Time'];
  width: Scalars['Float'];
};

export type BookElementFrame = {
  __typename?: 'BookElementFrame';
  createdAt: Scalars['Time'];
  frame: ElementImage;
  height: Scalars['Float'];
  id: Scalars['UUID'];
  image: ElementImage;
  imageHeight: Scalars['Float'];
  imageLeft: Scalars['Float'];
  imageTop: Scalars['Float'];
  imageWidth: Scalars['Float'];
  left: Scalars['Float'];
  mask: Mask;
  rotation: Scalars['Float'];
  round: Scalars['Boolean'];
  top: Scalars['Float'];
  type?: Maybe<ElementType>;
  updatedAt: Scalars['Time'];
  width: Scalars['Float'];
};

export type BookElementSticker = {
  __typename?: 'BookElementSticker';
  height: Scalars['Float'];
  id: Scalars['UUID'];
  image: ElementImage;
  left: Scalars['Float'];
  rotation: Scalars['Float'];
  top: Scalars['Float'];
  type?: Maybe<ElementType>;
  width: Scalars['Float'];
};

export type BookElementText = {
  __typename?: 'BookElementText';
  color: Scalars['String'];
  content: Scalars['String'];
  fontFamily: Scalars['String'];
  fontSize: Scalars['Float'];
  fontStyle: Scalars['String'];
  fontWeight: Scalars['Int'];
  height: Scalars['Float'];
  id: Scalars['UUID'];
  left: Scalars['Float'];
  placeholder: Scalars['String'];
  rotation: Scalars['Float'];
  textAlign: Scalars['String'];
  textDecoration: Scalars['String'];
  top: Scalars['Float'];
  type?: Maybe<ElementType>;
  width: Scalars['Float'];
};

export type BookImage = {
  __typename?: 'BookImage';
  aspectRatio?: Maybe<Scalars['Int']>;
  blobID: Scalars['UUID'];
  height?: Maybe<Scalars['Float']>;
  url: Scalars['String'];
  width?: Maybe<Scalars['Float']>;
};

export type BookMember = {
  __typename?: 'BookMember';
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['UUID'];
  lastName: Scalars['String'];
  role: MemberRole;
  status: MemberStatus;
};

export type BookOrderInput = {
  amount: Scalars['Int'];
  cover: BookCover;
  isGift: Scalars['Boolean'];
  paper: BookPaper;
  size: BookSize;
  productionSpeed: BookProductionSpeed;
  manualReviewRequested: Scalars['Boolean'];
};

export enum BookPaper {
  LayFlat = 'LAY_FLAT',
  Regular = 'REGULAR',
}

export enum BookProductionSpeed {
  Standard = 'STANDARD',
  Express = 'EXPRESS',
}

export type BookPrice = {
  __typename?: 'BookPrice';
  cover: Scalars['String'];
  delivery: Scalars['String'];
  gift: Scalars['String'];
  paper: Scalars['String'];
  size: Scalars['String'];
  spreads: Scalars['String'];
  productionSpeed: Scalars['String'];
  manualReviewRequested: Scalars['String'];
  /** Including 9% tax. */
  total: Scalars['String'];
  totalPerBook: Scalars['String'];
};

export enum BookSize {
  Large = 'LARGE',
  Medium = 'MEDIUM',
}

export type BookSpread = {
  __typename?: 'BookSpread';
  author?: Maybe<Author>;
  background?: Maybe<SpreadBackground>;
  elements: Array<GqlBookElement>;
  hasHistory: Scalars['Boolean'];
  id: Scalars['UUID'];
  isCollaborative: Scalars['Boolean'];
  isDuplicatable: Scalars['Boolean'];
  isEditable: Scalars['Boolean'];
  isTemplate: Scalars['Boolean'];
  thumbnailURL?: Maybe<Scalars['String']>;
};

export type ElementBlobInput = {
  height: Scalars['Float'];
  imageID: Scalars['UUID'];
  left: Scalars['Float'];
  rotation: Scalars['Float'];
  top: Scalars['Float'];
  width: Scalars['Float'];
};

export type ElementContainerInput = {
  height: Scalars['Float'];
  imageHeight: Scalars['Float'];
  imageID?: InputMaybe<Scalars['UUID']>;
  imageLeft: Scalars['Float'];
  imageTop: Scalars['Float'];
  imageWidth: Scalars['Float'];
  left: Scalars['Float'];
  rotation: Scalars['Float'];
  round: Scalars['Boolean'];
  top: Scalars['Float'];
  width: Scalars['Float'];
};

export type ElementFrameInput = {
  frameID: Scalars['UUID'];
  height: Scalars['Float'];
  imageHeight?: InputMaybe<Scalars['Float']>;
  imageID?: InputMaybe<Scalars['UUID']>;
  imageLeft?: InputMaybe<Scalars['Float']>;
  imageTop?: InputMaybe<Scalars['Float']>;
  imageWidth?: InputMaybe<Scalars['Float']>;
  left: Scalars['Float'];
  rotation: Scalars['Float'];
  top: Scalars['Float'];
  width: Scalars['Float'];
};

export type ElementImage = {
  __typename?: 'ElementImage';
  aspectRatio: Scalars['Float'];
  blobID: Scalars['UUID'];
  height: Scalars['Float'];
  url: Scalars['String'];
  width: Scalars['Float'];
};

export type ElementTextInput = {
  color: Scalars['String'];
  content: Scalars['String'];
  fontFamily: Scalars['String'];
  fontSize: Scalars['Float'];
  fontStyle: Scalars['String'];
  fontWeight: Scalars['Int'];
  height: Scalars['Float'];
  left: Scalars['Float'];
  placeholder: Scalars['String'];
  rotation: Scalars['Float'];
  textAlign: Scalars['String'];
  textDecoration: Scalars['String'];
  top: Scalars['Float'];
  width: Scalars['Float'];
};

export enum ElementType {
  Container = 'CONTAINER',
  Frame = 'FRAME',
  Sticker = 'STICKER',
  Text = 'TEXT',
}

export type GqlBookElement =
  | BookElementContainer
  | BookElementFrame
  | BookElementSticker
  | BookElementText;

export type Image = {
  __typename?: 'Image';
  blobID: Scalars['UUID'];
  originalHeight: Scalars['Int'];
  originalName: Scalars['String'];
  originalWidth: Scalars['Int'];
  status: ImageStatus;
  url: Scalars['String'];
  usageCount: Scalars['Int'];
};

export enum ImageStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export enum Mask {
  Circle = 'CIRCLE',
  Landscape = 'LANDSCAPE',
  Square = 'SQUARE',
}

export enum MemberRole {
  CoOwner = 'CO_OWNER',
  Owner = 'OWNER',
  Participant = 'PARTICIPANT',
}

export enum MemberStatus {
  Complete = 'COMPLETE',
  InProgress = 'IN_PROGRESS',
}

export type Money = {
  __typename?: 'Money';
  amount: Scalars['String'];
  currency: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  accountUpdate: Account;
  adminBackgroundReorder: Scalars['Boolean'];
  adminBackgroundUpdate: Image;
  adminBackgroundUpload: Image;
  adminFrameReorder: Scalars['Boolean'];
  adminFrameUpdate: Image;
  adminFrameUpload: Image;
  adminProductsReorder: Scalars['Boolean'];
  adminStickerReorder: Scalars['Boolean'];
  adminStickerUpdate: Image;
  adminStickerUpload: Image;
  bookContainerAdd: BookElementContainer;
  bookContainerUpdate: BookElementContainer;
  bookCreate: Scalars['UUID'];
  bookElementRemove: Scalars['Boolean'];
  bookElementReorder: Scalars['Boolean'];
  bookFrameAdd: BookElementFrame;
  bookFrameUpdate: BookElementFrame;
  bookInvitationTokenGenerate: Scalars['String'];
  bookInvitationTokenRemove: Scalars['Boolean'];
  bookJoin: Book;
  bookMarkAsComplete: Scalars['Boolean'];
  bookOrder: Scalars['String'];
  bookRemove: Scalars['Boolean'];
  bookSpineUpdate: Book;
  bookSpreadAdd: BookSpread;
  bookSpreadBackgroundUpdate: SpreadBackground;
  bookSpreadCreateCollaborative: Scalars['UUID'];
  bookSpreadDuplicate: Scalars['UUID'];
  bookSpreadRemove: Scalars['Boolean'];
  bookSpreadReorder: Scalars['Boolean'];
  bookSpreadToggleCollaborative: Scalars['Boolean'];
  bookSpreadUndo: Scalars['Boolean'];
  bookStickerAdd: BookElementSticker;
  bookStickerUpdate: BookElementSticker;
  bookTextAdd: BookElementText;
  bookTextUpdate: BookElementText;
  bookTitleUpdate: Book;
  bookToggleViewableSpreads: Scalars['Boolean'];
  bookUpdateMemberRole: Scalars['Boolean'];
  customImageRemove: Scalars['Boolean'];
  customImageUpload: Image;
  orderAssetsGenerate: Scalars['Boolean'];
  passwordChange: Tokens;
  passwordForgot: Scalars['Boolean'];
  passwordReset: Tokens;
  sendToPrinter: Scalars['Boolean'];
  signIn: Tokens;
  signOut: Scalars['Boolean'];
  signUp: Tokens;
};

export type MutationAccountUpdateArgs = {
  input: AccountInput;
};

export type MutationAdminBackgroundReorderArgs = {
  input: AdminImageReorderInput;
};

export type MutationAdminBackgroundUpdateArgs = {
  input: AdminImageUpdateInput;
};

export type MutationAdminBackgroundUploadArgs = {
  input: Scalars['Upload'];
};

export type MutationAdminFrameReorderArgs = {
  input: AdminImageReorderInput;
};

export type MutationAdminFrameUpdateArgs = {
  input: AdminImageUpdateInput;
};

export type MutationAdminFrameUploadArgs = {
  input: Scalars['Upload'];
  mask: Mask;
};

export type MutationAdminProductsReorderArgs = {
  input?: InputMaybe<ReorderInput>;
};

export type MutationAdminStickerReorderArgs = {
  input: AdminImageReorderInput;
};

export type MutationAdminStickerUpdateArgs = {
  input: AdminImageUpdateInput;
};

export type MutationAdminStickerUploadArgs = {
  input: Scalars['Upload'];
};

export type MutationBookContainerAddArgs = {
  input: ElementContainerInput;
  spreadID: Scalars['UUID'];
};

export type MutationBookContainerUpdateArgs = {
  elementID: Scalars['UUID'];
  input: ElementContainerInput;
};

export type MutationBookCreateArgs = {
  bookID: Scalars['UUID'];
};

export type MutationBookElementRemoveArgs = {
  elementID: Scalars['UUID'];
};

export type MutationBookElementReorderArgs = {
  input: ReorderInput;
  spreadID: Scalars['UUID'];
};

export type MutationBookFrameAddArgs = {
  input: ElementFrameInput;
  spreadID: Scalars['UUID'];
};

export type MutationBookFrameUpdateArgs = {
  elementID: Scalars['UUID'];
  input: ElementFrameInput;
};

export type MutationBookInvitationTokenGenerateArgs = {
  bookID: Scalars['UUID'];
};

export type MutationBookInvitationTokenRemoveArgs = {
  bookID: Scalars['UUID'];
};

export type MutationBookJoinArgs = {
  invitationToken: Scalars['UUID'];
};

export type MutationBookMarkAsCompleteArgs = {
  bookID: Scalars['UUID'];
};

export type MutationBookOrderArgs = {
  bookID: Scalars['UUID'];
  input: BookOrderInput;
};

export type MutationBookRemoveArgs = {
  bookID: Scalars['UUID'];
};

export type MutationBookSpineUpdateArgs = {
  bookID: Scalars['UUID'];
  input: SpineInput;
};

export type MutationBookSpreadAddArgs = {
  bookID: Scalars['UUID'];
};

export type MutationBookSpreadBackgroundUpdateArgs = {
  input: SpreadBackgroundInput;
  spreadID: Scalars['UUID'];
};

export type MutationBookSpreadCreateCollaborativeArgs = {
  bookID: Scalars['UUID'];
};

export type MutationBookSpreadDuplicateArgs = {
  spreadID: Scalars['UUID'];
};

export type MutationBookSpreadRemoveArgs = {
  spreadID: Scalars['UUID'];
};

export type MutationBookSpreadReorderArgs = {
  bookID: Scalars['UUID'];
  input: ReorderInput;
};

export type MutationBookSpreadToggleCollaborativeArgs = {
  spreadID: Scalars['UUID'];
};

export type MutationBookSpreadUndoArgs = {
  spreadID: Scalars['UUID'];
};

export type MutationBookStickerAddArgs = {
  input: ElementBlobInput;
  spreadID: Scalars['UUID'];
};

export type MutationBookStickerUpdateArgs = {
  elementID: Scalars['UUID'];
  input: ElementBlobInput;
};

export type MutationBookTextAddArgs = {
  input: ElementTextInput;
  spreadID: Scalars['UUID'];
};

export type MutationBookTextUpdateArgs = {
  elementID: Scalars['UUID'];
  input: ElementTextInput;
};

export type MutationBookTitleUpdateArgs = {
  bookID: Scalars['UUID'];
  input: Scalars['String'];
};

export type MutationBookToggleViewableSpreadsArgs = {
  bookID: Scalars['UUID'];
};

export type MutationBookUpdateMemberRoleArgs = {
  memberID: Scalars['UUID'];
  role: MemberRole;
};

export type MutationCustomImageRemoveArgs = {
  imageID: Scalars['UUID'];
};

export type MutationCustomImageUploadArgs = {
  bookID: Scalars['UUID'];
  input: Scalars['Upload'];
};

export type MutationOrderAssetsGenerateArgs = {
  orderID: Scalars['UUID'];
};

export type MutationPasswordChangeArgs = {
  password: Scalars['String'];
};

export type MutationPasswordForgotArgs = {
  email: Scalars['String'];
};

export type MutationPasswordResetArgs = {
  password: Scalars['String'];
  resetURL: Scalars['String'];
};

export type MutationSendToPrinterArgs = {
  orderID: Scalars['UUID'];
};

export type MutationSignInArgs = {
  input: SignInInput;
};

export type MutationSignUpArgs = {
  input: SignUpInput;
};

export type Order = {
  __typename?: 'Order';
  assets: OrderAssets;
  billingAddress: OrderAddress;
  bookID: Scalars['UUID'];
  cover: BookCover;
  createdAt: Scalars['Time'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['UUID'];
  lastName: Scalars['String'];
  orderNumber: Scalars['Int'];
  pageCount: Scalars['Int'];
  paper: BookPaper;
  priceShipping: Scalars['String'];
  priceTotal: Scalars['String'];
  productImage: Scalars['String'];
  quantity: Scalars['Int'];
  shippingAddress: OrderAddress;
  size: BookSize;
  status: OrderStatus;
  title: Scalars['String'];
};

export type OrderAddress = {
  __typename?: 'OrderAddress';
  address: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  zip: Scalars['String'];
};

export type OrderAssets = {
  __typename?: 'OrderAssets';
  body: Scalars['String'];
  cover: Scalars['String'];
};

export enum OrderStatus {
  AssetsGenerated = 'ASSETS_GENERATED',
  Printing = 'PRINTING',
  Processing = 'PROCESSING',
}

export type PageBackground = {
  __typename?: 'PageBackground';
  color?: Maybe<Scalars['String']>;
  image?: Maybe<BookImage>;
};

export type Product = {
  __typename?: 'Product';
  bookID: Scalars['UUID'];
  description?: Maybe<Scalars['String']>;
  exampleBookLarge?: Maybe<Scalars['String']>;
  exampleBookLargeMobile?: Maybe<Scalars['String']>;
  exampleBookMedium?: Maybe<Scalars['String']>;
  filters: Array<ProductFilter>;
  heroImage?: Maybe<Scalars['String']>;
  hideCTA: Scalars['Boolean'];
  id: Scalars['UUID'];
  image?: Maybe<Scalars['String']>;
  images: Array<Scalars['String']>;
  introImage?: Maybe<Scalars['String']>;
  introText?: Maybe<Scalars['String']>;
  introTitle?: Maybe<Scalars['String']>;
  promoColor?: Maybe<Scalars['String']>;
  promoImage?: Maybe<Scalars['String']>;
  promoTitle?: Maybe<Scalars['String']>;
  seo: Seo;
  slug: Scalars['String'];
  title: Scalars['String'];
};

export type ProductFilter = {
  __typename?: 'ProductFilter';
  category: Scalars['String'];
  id: Scalars['UUID'];
  value: Scalars['String'];
};

export type ProductFilterCategory = {
  __typename?: 'ProductFilterCategory';
  filters: Array<ProductFilter>;
  name: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  account: Account;
  adminOrders: Array<Order>;
  adminProducts: Array<Product>;
  backgrounds: Array<Image>;
  book: Book;
  bookInvitationToken?: Maybe<Scalars['String']>;
  bookMembers: Array<BookMember>;
  books: Array<Book>;
  calculatePrice: BookPrice;
  customImages: Array<Image>;
  element: GqlBookElement;
  featuredProducts: Array<Product>;
  frames: Array<Image>;
  order: Order;
  orders: Array<Order>;
  product: Product;
  productFilterCategories: Array<ProductFilterCategory>;
  products: Array<Product>;
  spread: BookSpread;
  stickers: Array<Image>;
};

export type QueryBookArgs = {
  bookID: Scalars['UUID'];
  invitationToken?: InputMaybe<Scalars['String']>;
};

export type QueryBookInvitationTokenArgs = {
  bookID: Scalars['UUID'];
};

export type QueryBookMembersArgs = {
  bookID: Scalars['UUID'];
};

export type QueryCalculatePriceArgs = {
  bookID: Scalars['UUID'];
  input: BookOrderInput;
};

export type QueryCustomImagesArgs = {
  bookID: Scalars['UUID'];
};

export type QueryElementArgs = {
  bookID: Scalars['UUID'];
  id: Scalars['UUID'];
};

export type QueryOrderArgs = {
  orderID: Scalars['UUID'];
};

export type QueryProductArgs = {
  slug: Scalars['String'];
};

export type QueryProductsArgs = {
  filters?: InputMaybe<Array<Scalars['UUID']>>;
};

export type QuerySpreadArgs = {
  spreadID: Scalars['UUID'];
};

export type RemoveElementMessage = {
  __typename?: 'RemoveElementMessage';
  id: Scalars['UUID'];
  spreadID: Scalars['UUID'];
};

export type RemoveSpreadMessage = {
  __typename?: 'RemoveSpreadMessage';
  id: Scalars['UUID'];
};

export type ReorderElementMessage = {
  __typename?: 'ReorderElementMessage';
  destinationIndex: Scalars['Int'];
  sourceIndex: Scalars['Int'];
  spreadID: Scalars['UUID'];
};

export type ReorderInput = {
  destinationIndex: Scalars['Int'];
  sourceIndex: Scalars['Int'];
};

export type ReorderSpreadMessage = {
  __typename?: 'ReorderSpreadMessage';
  destinationIndex: Scalars['Int'];
  sourceIndex: Scalars['Int'];
  spreadID: Scalars['UUID'];
};

export enum Role {
  Admin = 'ADMIN',
  User = 'USER',
}

export type Seo = {
  __typename?: 'SEO';
  description: Scalars['String'];
  title: Scalars['String'];
};

export type SignInInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type SignUpInput = {
  acceptsMarketing?: Scalars['Boolean'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
};

export type Spine = {
  __typename?: 'Spine';
  backgroundColor: Scalars['String'];
  text: Scalars['String'];
  textColor: Scalars['String'];
};

export type SpineInput = {
  backgroundColor: Scalars['String'];
  text: Scalars['String'];
  textColor: Scalars['String'];
};

export type SpreadBackground = {
  __typename?: 'SpreadBackground';
  left?: Maybe<PageBackground>;
  right?: Maybe<PageBackground>;
};

export type SpreadBackgroundInput = {
  leftColor?: InputMaybe<Scalars['String']>;
  leftImage?: InputMaybe<Scalars['UUID']>;
  rightColor?: InputMaybe<Scalars['String']>;
  rightImage?: InputMaybe<Scalars['UUID']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  addElement: AddElementMessage;
  removeElement: RemoveElementMessage;
  reorderElement: ReorderElementMessage;
  spineUpdate: UpdateSpineMessage;
  spreadAdd: AddSpreadMessage;
  spreadBackgroundUpdate: UpdateSpreadBackgroundMessage;
  spreadRemove: RemoveSpreadMessage;
  spreadReorder: ReorderSpreadMessage;
  spreadThumbnailUpdate: UpdateSpreadThumbnailMessage;
  undoElement: UndoElementMessage;
  updateElement: UpdateElementMessage;
};

export type SubscriptionAddElementArgs = {
  bookID: Scalars['UUID'];
};

export type SubscriptionRemoveElementArgs = {
  bookID: Scalars['UUID'];
};

export type SubscriptionReorderElementArgs = {
  bookID: Scalars['UUID'];
};

export type SubscriptionSpineUpdateArgs = {
  bookID: Scalars['UUID'];
};

export type SubscriptionSpreadAddArgs = {
  bookID: Scalars['UUID'];
};

export type SubscriptionSpreadBackgroundUpdateArgs = {
  bookID: Scalars['UUID'];
};

export type SubscriptionSpreadRemoveArgs = {
  bookID: Scalars['UUID'];
};

export type SubscriptionSpreadReorderArgs = {
  bookID: Scalars['UUID'];
};

export type SubscriptionSpreadThumbnailUpdateArgs = {
  bookID: Scalars['UUID'];
};

export type SubscriptionUndoElementArgs = {
  bookID: Scalars['UUID'];
};

export type SubscriptionUpdateElementArgs = {
  bookID: Scalars['UUID'];
};

export type Tokens = {
  __typename?: 'Tokens';
  accessToken: Scalars['String'];
  expiresAt: Scalars['Int'];
};

export type UndoElementMessage = {
  __typename?: 'UndoElementMessage';
  id: Scalars['UUID'];
  spreadID: Scalars['UUID'];
};

export type UpdateElementMessage = {
  __typename?: 'UpdateElementMessage';
  id: Scalars['UUID'];
  spreadID: Scalars['UUID'];
};

export type UpdateSpineMessage = {
  __typename?: 'UpdateSpineMessage';
  bookID: Scalars['UUID'];
};

export type UpdateSpreadBackgroundMessage = {
  __typename?: 'UpdateSpreadBackgroundMessage';
  spreadID: Scalars['UUID'];
};

export type UpdateSpreadThumbnailMessage = {
  __typename?: 'UpdateSpreadThumbnailMessage';
  spreadID: Scalars['UUID'];
  thumbnailURL: Scalars['String'];
};

export type AdminStickerUploadMutationVariables = Exact<{
  input: Scalars['Upload'];
}>;

export type AdminStickerUploadMutation = {
  __typename?: 'Mutation';
  adminStickerUpload: { __typename?: 'Image'; blobID: any; url: string };
};

export type AdminStickerReorderMutationVariables = Exact<{
  sourceIndex: Scalars['Int'];
  destinationIndex: Scalars['Int'];
}>;

export type AdminStickerReorderMutation = {
  __typename?: 'Mutation';
  adminStickerReorder: boolean;
};

export type AdminFrameUploadMutationVariables = Exact<{
  input: Scalars['Upload'];
  mask: Mask;
}>;

export type AdminFrameUploadMutation = {
  __typename?: 'Mutation';
  adminFrameUpload: { __typename?: 'Image'; blobID: any; url: string };
};

export type AdminFrameReorderMutationVariables = Exact<{
  sourceIndex: Scalars['Int'];
  destinationIndex: Scalars['Int'];
}>;

export type AdminFrameReorderMutation = {
  __typename?: 'Mutation';
  adminFrameReorder: boolean;
};

export type AdminBackgroundUploadMutationVariables = Exact<{
  input: Scalars['Upload'];
}>;

export type AdminBackgroundUploadMutation = {
  __typename?: 'Mutation';
  adminBackgroundUpload: { __typename?: 'Image'; blobID: any; url: string };
};

export type AdminBackgroundReorderMutationVariables = Exact<{
  sourceIndex: Scalars['Int'];
  destinationIndex: Scalars['Int'];
}>;

export type AdminBackgroundReorderMutation = {
  __typename?: 'Mutation';
  adminBackgroundReorder: boolean;
};

export type AdminProductsReorderMutationVariables = Exact<{
  sourceIndex: Scalars['Int'];
  destinationIndex: Scalars['Int'];
}>;

export type AdminProductsReorderMutation = {
  __typename?: 'Mutation';
  adminProductsReorder: boolean;
};

export type AdminOrdersQueryVariables = Exact<{ [key: string]: never }>;

export type AdminOrdersQuery = {
  __typename?: 'Query';
  adminOrders: Array<{
    __typename?: 'Order';
    id: any;
    firstName: string;
    lastName: string;
    email: string;
    createdAt: any;
    status: OrderStatus;
    bookID: any;
    assets: { __typename?: 'OrderAssets'; cover: string; body: string };
  }>;
};

export type OrderAssetsGenerateMutationVariables = Exact<{
  orderID: Scalars['UUID'];
}>;

export type OrderAssetsGenerateMutation = {
  __typename?: 'Mutation';
  orderAssetsGenerate: boolean;
};

export type SendToPrinterMutationVariables = Exact<{
  orderID: Scalars['UUID'];
}>;

export type SendToPrinterMutation = {
  __typename?: 'Mutation';
  sendToPrinter: boolean;
};

export type SignUpMutationVariables = Exact<{
  input: SignUpInput;
}>;

export type SignUpMutation = {
  __typename?: 'Mutation';
  signUp: { __typename?: 'Tokens'; accessToken: string };
};

export type SignInMutationVariables = Exact<{
  input: SignInInput;
}>;

export type SignInMutation = {
  __typename?: 'Mutation';
  signIn: { __typename?: 'Tokens'; accessToken: string };
};

export type SignOutMutationVariables = Exact<{ [key: string]: never }>;

export type SignOutMutation = { __typename?: 'Mutation'; signOut: boolean };

export type GetAccountInfoQueryVariables = Exact<{ [key: string]: never }>;

export type GetAccountInfoQuery = {
  __typename?: 'Query';
  account: {
    __typename?: 'Account';
    id: any;
    firstName: string;
    lastName: string;
    email: string;
    role: Role;
  };
};

export type PasswordForgotnMutationVariables = Exact<{
  email: Scalars['String'];
}>;

export type PasswordForgotnMutation = {
  __typename?: 'Mutation';
  passwordForgot: boolean;
};

export type PasswordResetMutationVariables = Exact<{
  resetUrl: Scalars['String'];
  password: Scalars['String'];
}>;

export type PasswordResetMutation = {
  __typename?: 'Mutation';
  passwordReset: { __typename?: 'Tokens'; accessToken: string };
};

export type BookCreateMutationVariables = Exact<{
  bookID: Scalars['UUID'];
}>;

export type BookCreateMutation = { __typename?: 'Mutation'; bookCreate: any };

export type GetPrintSpreadQueryVariables = Exact<{
  spreadID: Scalars['UUID'];
}>;

export type GetPrintSpreadQuery = {
  __typename?: 'Query';
  spread: {
    __typename?: 'BookSpread';
    id: any;
    isTemplate: boolean;
    isCollaborative: boolean;
    isDuplicatable: boolean;
    isEditable: boolean;
    hasHistory: boolean;
    thumbnailURL?: string | undefined;
    author?:
      | {
          __typename?: 'Author';
          email: string;
          firstName: string;
          lastName: string;
        }
      | undefined;
    elements: Array<
      | {
          __typename?: 'BookElementContainer';
          id: any;
          type?: ElementType | undefined;
          width: number;
          height: number;
          top: number;
          left: number;
          rotation: number;
          round: boolean;
          imageWidth: number;
          imageHeight: number;
          imageTop: number;
          imageLeft: number;
          createdAt: any;
          image: {
            __typename?: 'ElementImage';
            blobID: any;
            url: string;
            aspectRatio: number;
            height: number;
            width: number;
          };
        }
      | {
          __typename?: 'BookElementFrame';
          id: any;
          type?: ElementType | undefined;
          width: number;
          height: number;
          top: number;
          left: number;
          rotation: number;
          mask: Mask;
          round: boolean;
          imageWidth: number;
          imageHeight: number;
          imageTop: number;
          imageLeft: number;
          createdAt: any;
          image: {
            __typename?: 'ElementImage';
            blobID: any;
            url: string;
            aspectRatio: number;
            height: number;
            width: number;
          };
          frame: {
            __typename?: 'ElementImage';
            blobID: any;
            url: string;
            aspectRatio: number;
            height: number;
            width: number;
          };
        }
      | {
          __typename?: 'BookElementSticker';
          id: any;
          type?: ElementType | undefined;
          width: number;
          height: number;
          rotation: number;
          top: number;
          left: number;
          image: {
            __typename?: 'ElementImage';
            blobID: any;
            url: string;
            aspectRatio: number;
            height: number;
            width: number;
          };
        }
      | {
          __typename?: 'BookElementText';
          id: any;
          type?: ElementType | undefined;
          width: number;
          height: number;
          rotation: number;
          top: number;
          left: number;
          content: string;
          placeholder: string;
          color: string;
          fontFamily: string;
          fontSize: number;
          fontWeight: number;
          fontStyle: string;
          textDecoration: string;
          textAlign: string;
        }
    >;
    background?:
      | {
          __typename?: 'SpreadBackground';
          left?:
            | {
                __typename?: 'PageBackground';
                color?: string | undefined;
                image?:
                  | { __typename?: 'BookImage'; url: string; blobID: any }
                  | undefined;
              }
            | undefined;
          right?:
            | {
                __typename?: 'PageBackground';
                color?: string | undefined;
                image?:
                  | { __typename?: 'BookImage'; url: string; blobID: any }
                  | undefined;
              }
            | undefined;
        }
      | undefined;
  };
};

export type GetPrintSpineQueryVariables = Exact<{
  bookID: Scalars['UUID'];
}>;

export type GetPrintSpineQuery = {
  __typename?: 'Query';
  book: {
    __typename?: 'Book';
    spine: {
      __typename?: 'Spine';
      text: string;
      textColor: string;
      backgroundColor: string;
    };
  };
};

export type CalculatePriceQueryVariables = Exact<{
  bookID: Scalars['UUID'];
  input: BookOrderInput;
}>;

export type CalculatePriceQuery = {
  __typename?: 'Query';
  calculatePrice: {
    __typename?: 'BookPrice';
    size: string;
    paper: string;
    spreads: string;
    cover: string;
    gift: string;
    delivery: string;
    productionSpeed: string;
    manualReviewRequested: string;
    totalPerBook: string;
    total: string;
  };
};

export type BookOrderMutationVariables = Exact<{
  bookID: Scalars['UUID'];
  input: BookOrderInput;
}>;

export type BookOrderMutation = { __typename?: 'Mutation'; bookOrder: string };

export type BookRemoveMutationVariables = Exact<{
  bookID: Scalars['UUID'];
}>;

export type BookRemoveMutation = {
  __typename?: 'Mutation';
  bookRemove: boolean;
};

export type BookTitleUpdateMutationVariables = Exact<{
  bookID: Scalars['UUID'];
  input: Scalars['String'];
}>;

export type BookTitleUpdateMutation = {
  __typename?: 'Mutation';
  bookTitleUpdate: { __typename?: 'Book'; id: any; title: string };
};

export type BookUpdateMemberRoleMutationVariables = Exact<{
  memberID: Scalars['UUID'];
  role: MemberRole;
}>;

export type BookUpdateMemberRoleMutation = {
  __typename?: 'Mutation';
  bookUpdateMemberRole: boolean;
};

export type GetBookByIdQueryVariables = Exact<{
  bookId: Scalars['UUID'];
  invitationToken?: InputMaybe<Scalars['String']>;
}>;

export type GetBookByIdQuery = {
  __typename?: 'Query';
  book: {
    __typename?: 'Book';
    id: any;
    title: string;
    isTemplate: boolean;
    isDeletable: boolean;
    memberRole?: MemberRole | undefined;
    memberStatus?: MemberStatus | undefined;
    viewableSpreads: boolean;
    modifiedAt: any;
    owner?:
      | { __typename?: 'Account'; email: string; firstName: string; id: any }
      | undefined;
    spine: {
      __typename?: 'Spine';
      text: string;
      textColor: string;
      backgroundColor: string;
    };
    spreads: Array<{
      __typename?: 'BookSpread';
      id: any;
      isTemplate: boolean;
      isCollaborative: boolean;
      isDuplicatable: boolean;
      isEditable: boolean;
      hasHistory: boolean;
      thumbnailURL?: string | undefined;
      author?:
        | {
            __typename?: 'Author';
            email: string;
            firstName: string;
            lastName: string;
          }
        | undefined;
      elements: Array<
        | {
            __typename?: 'BookElementContainer';
            id: any;
            type?: ElementType | undefined;
            width: number;
            height: number;
            top: number;
            left: number;
            rotation: number;
            round: boolean;
            imageWidth: number;
            imageHeight: number;
            imageTop: number;
            imageLeft: number;
            createdAt: any;
            image: {
              __typename?: 'ElementImage';
              blobID: any;
              url: string;
              aspectRatio: number;
              height: number;
              width: number;
            };
          }
        | {
            __typename?: 'BookElementFrame';
            id: any;
            type?: ElementType | undefined;
            width: number;
            height: number;
            top: number;
            left: number;
            rotation: number;
            mask: Mask;
            round: boolean;
            imageWidth: number;
            imageHeight: number;
            imageTop: number;
            imageLeft: number;
            createdAt: any;
            image: {
              __typename?: 'ElementImage';
              blobID: any;
              url: string;
              aspectRatio: number;
              height: number;
              width: number;
            };
            frame: {
              __typename?: 'ElementImage';
              blobID: any;
              url: string;
              aspectRatio: number;
              height: number;
              width: number;
            };
          }
        | {
            __typename?: 'BookElementSticker';
            id: any;
            type?: ElementType | undefined;
            width: number;
            height: number;
            rotation: number;
            top: number;
            left: number;
            image: {
              __typename?: 'ElementImage';
              blobID: any;
              url: string;
              aspectRatio: number;
              height: number;
              width: number;
            };
          }
        | {
            __typename?: 'BookElementText';
            id: any;
            type?: ElementType | undefined;
            width: number;
            height: number;
            rotation: number;
            top: number;
            left: number;
            content: string;
            placeholder: string;
            color: string;
            fontFamily: string;
            fontSize: number;
            fontWeight: number;
            fontStyle: string;
            textDecoration: string;
            textAlign: string;
          }
      >;
      background?:
        | {
            __typename?: 'SpreadBackground';
            left?:
              | {
                  __typename?: 'PageBackground';
                  color?: string | undefined;
                  image?:
                    | { __typename?: 'BookImage'; url: string; blobID: any }
                    | undefined;
                }
              | undefined;
            right?:
              | {
                  __typename?: 'PageBackground';
                  color?: string | undefined;
                  image?:
                    | { __typename?: 'BookImage'; url: string; blobID: any }
                    | undefined;
                }
              | undefined;
          }
        | undefined;
    }>;
  };
};

export type GetSpineQueryVariables = Exact<{
  bookId: Scalars['UUID'];
}>;

export type GetSpineQuery = {
  __typename?: 'Query';
  book: {
    __typename?: 'Book';
    id: any;
    spine: {
      __typename?: 'Spine';
      text: string;
      textColor: string;
      backgroundColor: string;
    };
  };
};

export type GetSpreadByIdQueryVariables = Exact<{
  spreadId: Scalars['UUID'];
}>;

export type GetSpreadByIdQuery = {
  __typename?: 'Query';
  spread: {
    __typename?: 'BookSpread';
    id: any;
    isTemplate: boolean;
    isCollaborative: boolean;
    isDuplicatable: boolean;
    isEditable: boolean;
    hasHistory: boolean;
    thumbnailURL?: string | undefined;
    author?:
      | {
          __typename?: 'Author';
          email: string;
          firstName: string;
          lastName: string;
        }
      | undefined;
    elements: Array<
      | {
          __typename?: 'BookElementContainer';
          id: any;
          type?: ElementType | undefined;
          width: number;
          height: number;
          top: number;
          left: number;
          rotation: number;
          round: boolean;
          imageWidth: number;
          imageHeight: number;
          imageTop: number;
          imageLeft: number;
          createdAt: any;
          image: {
            __typename?: 'ElementImage';
            blobID: any;
            url: string;
            aspectRatio: number;
            height: number;
            width: number;
          };
        }
      | {
          __typename?: 'BookElementFrame';
          id: any;
          type?: ElementType | undefined;
          width: number;
          height: number;
          top: number;
          left: number;
          rotation: number;
          mask: Mask;
          round: boolean;
          imageWidth: number;
          imageHeight: number;
          imageTop: number;
          imageLeft: number;
          createdAt: any;
          image: {
            __typename?: 'ElementImage';
            blobID: any;
            url: string;
            aspectRatio: number;
            height: number;
            width: number;
          };
          frame: {
            __typename?: 'ElementImage';
            blobID: any;
            url: string;
            aspectRatio: number;
            height: number;
            width: number;
          };
        }
      | {
          __typename?: 'BookElementSticker';
          id: any;
          type?: ElementType | undefined;
          width: number;
          height: number;
          rotation: number;
          top: number;
          left: number;
          image: {
            __typename?: 'ElementImage';
            blobID: any;
            url: string;
            aspectRatio: number;
            height: number;
            width: number;
          };
        }
      | {
          __typename?: 'BookElementText';
          id: any;
          type?: ElementType | undefined;
          width: number;
          height: number;
          rotation: number;
          top: number;
          left: number;
          content: string;
          placeholder: string;
          color: string;
          fontFamily: string;
          fontSize: number;
          fontWeight: number;
          fontStyle: string;
          textDecoration: string;
          textAlign: string;
        }
    >;
    background?:
      | {
          __typename?: 'SpreadBackground';
          left?:
            | {
                __typename?: 'PageBackground';
                color?: string | undefined;
                image?:
                  | { __typename?: 'BookImage'; url: string; blobID: any }
                  | undefined;
              }
            | undefined;
          right?:
            | {
                __typename?: 'PageBackground';
                color?: string | undefined;
                image?:
                  | { __typename?: 'BookImage'; url: string; blobID: any }
                  | undefined;
              }
            | undefined;
        }
      | undefined;
  };
};

export type GetCustomImagesQueryVariables = Exact<{
  bookId: Scalars['UUID'];
}>;

export type GetCustomImagesQuery = {
  __typename?: 'Query';
  customImages: Array<{
    __typename?: 'Image';
    blobID: any;
    url: string;
    originalWidth: number;
    originalHeight: number;
    usageCount: number;
  }>;
};

export type GetMediaQueryVariables = Exact<{ [key: string]: never }>;

export type GetMediaQuery = {
  __typename?: 'Query';
  stickers: Array<{
    __typename?: 'Image';
    blobID: any;
    url: string;
    originalWidth: number;
    originalHeight: number;
    usageCount: number;
  }>;
  frames: Array<{
    __typename?: 'Image';
    blobID: any;
    url: string;
    originalWidth: number;
    originalHeight: number;
    usageCount: number;
  }>;
  backgrounds: Array<{
    __typename?: 'Image';
    blobID: any;
    url: string;
    originalWidth: number;
    originalHeight: number;
    usageCount: number;
  }>;
};

export type SpineUpdateMutationVariables = Exact<{
  bookId: Scalars['UUID'];
  text: Scalars['String'];
  textColor: Scalars['String'];
  backgroundColor: Scalars['String'];
}>;

export type SpineUpdateMutation = {
  __typename?: 'Mutation';
  bookSpineUpdate: {
    __typename?: 'Book';
    id: any;
    spine: {
      __typename?: 'Spine';
      text: string;
      textColor: string;
      backgroundColor: string;
    };
  };
};

export type SpreadAddMutationVariables = Exact<{
  bookId: Scalars['UUID'];
}>;

export type SpreadAddMutation = {
  __typename?: 'Mutation';
  bookSpreadAdd: {
    __typename?: 'BookSpread';
    id: any;
    isTemplate: boolean;
    isCollaborative: boolean;
    isDuplicatable: boolean;
    isEditable: boolean;
    hasHistory: boolean;
    thumbnailURL?: string | undefined;
    author?:
      | {
          __typename?: 'Author';
          email: string;
          firstName: string;
          lastName: string;
        }
      | undefined;
    elements: Array<
      | {
          __typename?: 'BookElementContainer';
          id: any;
          type?: ElementType | undefined;
          width: number;
          height: number;
          top: number;
          left: number;
          rotation: number;
          round: boolean;
          imageWidth: number;
          imageHeight: number;
          imageTop: number;
          imageLeft: number;
          createdAt: any;
          image: {
            __typename?: 'ElementImage';
            blobID: any;
            url: string;
            aspectRatio: number;
            height: number;
            width: number;
          };
        }
      | {
          __typename?: 'BookElementFrame';
          id: any;
          type?: ElementType | undefined;
          width: number;
          height: number;
          top: number;
          left: number;
          rotation: number;
          mask: Mask;
          round: boolean;
          imageWidth: number;
          imageHeight: number;
          imageTop: number;
          imageLeft: number;
          createdAt: any;
          image: {
            __typename?: 'ElementImage';
            blobID: any;
            url: string;
            aspectRatio: number;
            height: number;
            width: number;
          };
          frame: {
            __typename?: 'ElementImage';
            blobID: any;
            url: string;
            aspectRatio: number;
            height: number;
            width: number;
          };
        }
      | {
          __typename?: 'BookElementSticker';
          id: any;
          type?: ElementType | undefined;
          width: number;
          height: number;
          rotation: number;
          top: number;
          left: number;
          image: {
            __typename?: 'ElementImage';
            blobID: any;
            url: string;
            aspectRatio: number;
            height: number;
            width: number;
          };
        }
      | {
          __typename?: 'BookElementText';
          id: any;
          type?: ElementType | undefined;
          width: number;
          height: number;
          rotation: number;
          top: number;
          left: number;
          content: string;
          placeholder: string;
          color: string;
          fontFamily: string;
          fontSize: number;
          fontWeight: number;
          fontStyle: string;
          textDecoration: string;
          textAlign: string;
        }
    >;
    background?:
      | {
          __typename?: 'SpreadBackground';
          left?:
            | {
                __typename?: 'PageBackground';
                color?: string | undefined;
                image?:
                  | { __typename?: 'BookImage'; url: string; blobID: any }
                  | undefined;
              }
            | undefined;
          right?:
            | {
                __typename?: 'PageBackground';
                color?: string | undefined;
                image?:
                  | { __typename?: 'BookImage'; url: string; blobID: any }
                  | undefined;
              }
            | undefined;
        }
      | undefined;
  };
};

export type SpreadReorderMutationVariables = Exact<{
  bookId: Scalars['UUID'];
  sourceIndex: Scalars['Int'];
  destinationIndex: Scalars['Int'];
}>;

export type SpreadReorderMutation = {
  __typename?: 'Mutation';
  bookSpreadReorder: boolean;
};

export type SpreadRemoveMutationVariables = Exact<{
  spreadId: Scalars['UUID'];
}>;

export type SpreadRemoveMutation = {
  __typename?: 'Mutation';
  bookSpreadRemove: boolean;
};

export type SpreadBackgroundUpdateMutationVariables = Exact<{
  spreadId: Scalars['UUID'];
  leftImageId?: InputMaybe<Scalars['UUID']>;
  rightImageId?: InputMaybe<Scalars['UUID']>;
  leftColor?: InputMaybe<Scalars['String']>;
  rightColor?: InputMaybe<Scalars['String']>;
}>;

export type SpreadBackgroundUpdateMutation = {
  __typename?: 'Mutation';
  bookSpreadBackgroundUpdate: { __typename: 'SpreadBackground' };
};

export type ImageUploadMutationVariables = Exact<{
  bookId: Scalars['UUID'];
  input: Scalars['Upload'];
}>;

export type ImageUploadMutation = {
  __typename?: 'Mutation';
  customImageUpload: { __typename?: 'Image'; blobID: any; url: string };
};

export type ImageRemoveMutationVariables = Exact<{
  imageId: Scalars['UUID'];
}>;

export type ImageRemoveMutation = {
  __typename?: 'Mutation';
  customImageRemove: boolean;
};

export type ContainerAddMutationVariables = Exact<{
  spreadId: Scalars['UUID'];
  width: Scalars['Float'];
  height: Scalars['Float'];
  top?: InputMaybe<Scalars['Float']>;
  left?: InputMaybe<Scalars['Float']>;
  rotation?: InputMaybe<Scalars['Float']>;
  round?: InputMaybe<Scalars['Boolean']>;
  imageId?: InputMaybe<Scalars['UUID']>;
  imageTop?: Scalars['Float'];
  imageLeft?: Scalars['Float'];
  imageWidth?: Scalars['Float'];
  imageHeight?: Scalars['Float'];
}>;

export type ContainerAddMutation = {
  __typename?: 'Mutation';
  bookContainerAdd: { __typename?: 'BookElementContainer'; id: any };
};

export type FrameAddMutationVariables = Exact<{
  spreadId: Scalars['UUID'];
  top?: InputMaybe<Scalars['Float']>;
  left?: InputMaybe<Scalars['Float']>;
  width: Scalars['Float'];
  height: Scalars['Float'];
  rotation?: InputMaybe<Scalars['Float']>;
  frameId: Scalars['UUID'];
  imageId?: InputMaybe<Scalars['UUID']>;
  imageTop?: Scalars['Float'];
  imageLeft?: Scalars['Float'];
  imageWidth?: Scalars['Float'];
  imageHeight?: Scalars['Float'];
}>;

export type FrameAddMutation = {
  __typename?: 'Mutation';
  bookFrameAdd: { __typename?: 'BookElementFrame'; id: any };
};

export type StickerAddMutationVariables = Exact<{
  spreadId: Scalars['UUID'];
  width: Scalars['Float'];
  height: Scalars['Float'];
  top: Scalars['Float'];
  left: Scalars['Float'];
  rotation?: InputMaybe<Scalars['Float']>;
  imageId: Scalars['UUID'];
}>;

export type StickerAddMutation = {
  __typename?: 'Mutation';
  bookStickerAdd: { __typename?: 'BookElementSticker'; id: any };
};

export type TextAddMutationVariables = Exact<{
  spreadId: Scalars['UUID'];
  width?: Scalars['Float'];
  height?: Scalars['Float'];
  top?: InputMaybe<Scalars['Float']>;
  left?: InputMaybe<Scalars['Float']>;
  rotation?: InputMaybe<Scalars['Float']>;
  content: Scalars['String'];
  placeholder: Scalars['String'];
  fontFamily?: InputMaybe<Scalars['String']>;
  fontSize?: InputMaybe<Scalars['Float']>;
  fontWeight?: InputMaybe<Scalars['Int']>;
  fontStyle?: InputMaybe<Scalars['String']>;
  textDecoration?: InputMaybe<Scalars['String']>;
  textAlign?: InputMaybe<Scalars['String']>;
  color?: InputMaybe<Scalars['String']>;
}>;

export type TextAddMutation = {
  __typename?: 'Mutation';
  bookTextAdd: { __typename?: 'BookElementText'; id: any };
};

export type ContainerUpdateMutationVariables = Exact<{
  elementId: Scalars['UUID'];
  width: Scalars['Float'];
  height: Scalars['Float'];
  top: Scalars['Float'];
  left: Scalars['Float'];
  rotation: Scalars['Float'];
  round: Scalars['Boolean'];
  imageId?: InputMaybe<Scalars['UUID']>;
  imageTop: Scalars['Float'];
  imageLeft: Scalars['Float'];
  imageWidth: Scalars['Float'];
  imageHeight: Scalars['Float'];
}>;

export type ContainerUpdateMutation = {
  __typename?: 'Mutation';
  bookContainerUpdate: {
    __typename?: 'BookElementContainer';
    id: any;
    width: number;
    height: number;
    top: number;
    left: number;
    rotation: number;
    round: boolean;
    imageTop: number;
    imageLeft: number;
    imageWidth: number;
    imageHeight: number;
    image: {
      __typename?: 'ElementImage';
      blobID: any;
      url: string;
      aspectRatio: number;
    };
  };
};

export type FrameUpdateMutationVariables = Exact<{
  elementId: Scalars['UUID'];
  top: Scalars['Float'];
  left: Scalars['Float'];
  width: Scalars['Float'];
  height: Scalars['Float'];
  rotation: Scalars['Float'];
  frameId: Scalars['UUID'];
  imageId?: InputMaybe<Scalars['UUID']>;
  imageTop: Scalars['Float'];
  imageLeft: Scalars['Float'];
  imageWidth: Scalars['Float'];
  imageHeight: Scalars['Float'];
}>;

export type FrameUpdateMutation = {
  __typename?: 'Mutation';
  bookFrameUpdate: {
    __typename?: 'BookElementFrame';
    id: any;
    width: number;
    height: number;
    top: number;
    left: number;
    rotation: number;
    round: boolean;
    imageTop: number;
    imageLeft: number;
    imageWidth: number;
    imageHeight: number;
    frame: { __typename?: 'ElementImage'; blobID: any; url: string };
    image: {
      __typename?: 'ElementImage';
      blobID: any;
      url: string;
      aspectRatio: number;
    };
  };
};

export type StickerUpdateMutationVariables = Exact<{
  elementId: Scalars['UUID'];
  width: Scalars['Float'];
  height: Scalars['Float'];
  top: Scalars['Float'];
  left: Scalars['Float'];
  rotation: Scalars['Float'];
  imageId: Scalars['UUID'];
}>;

export type StickerUpdateMutation = {
  __typename?: 'Mutation';
  bookStickerUpdate: {
    __typename?: 'BookElementSticker';
    id: any;
    width: number;
    height: number;
    top: number;
    left: number;
    rotation: number;
    image: {
      __typename?: 'ElementImage';
      blobID: any;
      url: string;
      aspectRatio: number;
    };
  };
};

export type TextUpdateMutationVariables = Exact<{
  elementId: Scalars['UUID'];
  width: Scalars['Float'];
  height: Scalars['Float'];
  top: Scalars['Float'];
  left: Scalars['Float'];
  rotation: Scalars['Float'];
  content: Scalars['String'];
  placeholder: Scalars['String'];
  fontFamily: Scalars['String'];
  fontSize: Scalars['Float'];
  fontWeight: Scalars['Int'];
  fontStyle: Scalars['String'];
  textDecoration: Scalars['String'];
  textAlign: Scalars['String'];
  color: Scalars['String'];
}>;

export type TextUpdateMutation = {
  __typename?: 'Mutation';
  bookTextUpdate: {
    __typename?: 'BookElementText';
    id: any;
    width: number;
    height: number;
    top: number;
    left: number;
    rotation: number;
    content: string;
    placeholder: string;
    fontFamily: string;
    fontSize: number;
    fontWeight: number;
    fontStyle: string;
    textDecoration: string;
    textAlign: string;
    color: string;
  };
};

export type ElementRemoveMutationVariables = Exact<{
  elementId: Scalars['UUID'];
}>;

export type ElementRemoveMutation = {
  __typename?: 'Mutation';
  bookElementRemove: boolean;
};

export type ElementReorderMutationVariables = Exact<{
  spreadId: Scalars['UUID'];
  sourceIndex: Scalars['Int'];
  destinationIndex: Scalars['Int'];
}>;

export type ElementReorderMutation = {
  __typename?: 'Mutation';
  bookElementReorder: boolean;
};

export type BookSpreadToggleCollaborativeMutationVariables = Exact<{
  spreadId: Scalars['UUID'];
}>;

export type BookSpreadToggleCollaborativeMutation = {
  __typename?: 'Mutation';
  bookSpreadToggleCollaborative: boolean;
};

export type BookInvitationTokenGenerateMutationVariables = Exact<{
  bookID: Scalars['UUID'];
}>;

export type BookInvitationTokenGenerateMutation = {
  __typename?: 'Mutation';
  bookInvitationTokenGenerate: string;
};

export type GetBookInvitationTokenQueryVariables = Exact<{
  bookID: Scalars['UUID'];
}>;

export type GetBookInvitationTokenQuery = {
  __typename?: 'Query';
  bookInvitationToken?: string | undefined;
};

export type BookSpreadDuplicateMutationVariables = Exact<{
  spreadId: Scalars['UUID'];
}>;

export type BookSpreadDuplicateMutation = {
  __typename?: 'Mutation';
  bookSpreadDuplicate: any;
};

export type BookJoinMutationVariables = Exact<{
  invitationToken: Scalars['UUID'];
}>;

export type BookJoinMutation = {
  __typename?: 'Mutation';
  bookJoin: {
    __typename?: 'Book';
    id: any;
    spreads: Array<{
      __typename?: 'BookSpread';
      id: any;
      isTemplate: boolean;
      isCollaborative: boolean;
      isDuplicatable: boolean;
      isEditable: boolean;
      hasHistory: boolean;
      thumbnailURL?: string | undefined;
      author?:
        | {
            __typename?: 'Author';
            email: string;
            firstName: string;
            lastName: string;
          }
        | undefined;
      elements: Array<
        | {
            __typename?: 'BookElementContainer';
            id: any;
            type?: ElementType | undefined;
            width: number;
            height: number;
            top: number;
            left: number;
            rotation: number;
            round: boolean;
            imageWidth: number;
            imageHeight: number;
            imageTop: number;
            imageLeft: number;
            createdAt: any;
            image: {
              __typename?: 'ElementImage';
              blobID: any;
              url: string;
              aspectRatio: number;
              height: number;
              width: number;
            };
          }
        | {
            __typename?: 'BookElementFrame';
            id: any;
            type?: ElementType | undefined;
            width: number;
            height: number;
            top: number;
            left: number;
            rotation: number;
            mask: Mask;
            round: boolean;
            imageWidth: number;
            imageHeight: number;
            imageTop: number;
            imageLeft: number;
            createdAt: any;
            image: {
              __typename?: 'ElementImage';
              blobID: any;
              url: string;
              aspectRatio: number;
              height: number;
              width: number;
            };
            frame: {
              __typename?: 'ElementImage';
              blobID: any;
              url: string;
              aspectRatio: number;
              height: number;
              width: number;
            };
          }
        | {
            __typename?: 'BookElementSticker';
            id: any;
            type?: ElementType | undefined;
            width: number;
            height: number;
            rotation: number;
            top: number;
            left: number;
            image: {
              __typename?: 'ElementImage';
              blobID: any;
              url: string;
              aspectRatio: number;
              height: number;
              width: number;
            };
          }
        | {
            __typename?: 'BookElementText';
            id: any;
            type?: ElementType | undefined;
            width: number;
            height: number;
            rotation: number;
            top: number;
            left: number;
            content: string;
            placeholder: string;
            color: string;
            fontFamily: string;
            fontSize: number;
            fontWeight: number;
            fontStyle: string;
            textDecoration: string;
            textAlign: string;
          }
      >;
      background?:
        | {
            __typename?: 'SpreadBackground';
            left?:
              | {
                  __typename?: 'PageBackground';
                  color?: string | undefined;
                  image?:
                    | { __typename?: 'BookImage'; url: string; blobID: any }
                    | undefined;
                }
              | undefined;
            right?:
              | {
                  __typename?: 'PageBackground';
                  color?: string | undefined;
                  image?:
                    | { __typename?: 'BookImage'; url: string; blobID: any }
                    | undefined;
                }
              | undefined;
          }
        | undefined;
    }>;
  };
};

export type BookSpreadCreateCollaborativeMutationVariables = Exact<{
  bookID: Scalars['UUID'];
}>;

export type BookSpreadCreateCollaborativeMutation = {
  __typename?: 'Mutation';
  bookSpreadCreateCollaborative: any;
};

export type BookMarkAsCompleteMutationVariables = Exact<{
  bookID: Scalars['UUID'];
}>;

export type BookMarkAsCompleteMutation = {
  __typename?: 'Mutation';
  bookMarkAsComplete: boolean;
};

export type UndoSpreadMutationVariables = Exact<{
  spreadId: Scalars['UUID'];
}>;

export type UndoSpreadMutation = {
  __typename?: 'Mutation';
  bookSpreadUndo: boolean;
};

export type BookToggleViewableSpreadsMutationVariables = Exact<{
  bookID: Scalars['UUID'];
}>;

export type BookToggleViewableSpreadsMutation = {
  __typename?: 'Mutation';
  bookToggleViewableSpreads: boolean;
};

export type SpineFragment = {
  __typename?: 'Spine';
  text: string;
  textColor: string;
  backgroundColor: string;
};

export type BlobImageFragment = {
  __typename?: 'Image';
  blobID: any;
  url: string;
  originalWidth: number;
  originalHeight: number;
  usageCount: number;
};

export type ElementImageFragment = {
  __typename?: 'ElementImage';
  blobID: any;
  url: string;
  aspectRatio: number;
  height: number;
  width: number;
};

export type SpreadFragment = {
  __typename?: 'BookSpread';
  id: any;
  isTemplate: boolean;
  isCollaborative: boolean;
  isDuplicatable: boolean;
  isEditable: boolean;
  hasHistory: boolean;
  thumbnailURL?: string | undefined;
  author?:
    | {
        __typename?: 'Author';
        email: string;
        firstName: string;
        lastName: string;
      }
    | undefined;
  elements: Array<
    | {
        __typename?: 'BookElementContainer';
        id: any;
        type?: ElementType | undefined;
        width: number;
        height: number;
        top: number;
        left: number;
        rotation: number;
        round: boolean;
        imageWidth: number;
        imageHeight: number;
        imageTop: number;
        imageLeft: number;
        createdAt: any;
        image: {
          __typename?: 'ElementImage';
          blobID: any;
          url: string;
          aspectRatio: number;
          height: number;
          width: number;
        };
      }
    | {
        __typename?: 'BookElementFrame';
        id: any;
        type?: ElementType | undefined;
        width: number;
        height: number;
        top: number;
        left: number;
        rotation: number;
        mask: Mask;
        round: boolean;
        imageWidth: number;
        imageHeight: number;
        imageTop: number;
        imageLeft: number;
        createdAt: any;
        image: {
          __typename?: 'ElementImage';
          blobID: any;
          url: string;
          aspectRatio: number;
          height: number;
          width: number;
        };
        frame: {
          __typename?: 'ElementImage';
          blobID: any;
          url: string;
          aspectRatio: number;
          height: number;
          width: number;
        };
      }
    | {
        __typename?: 'BookElementSticker';
        id: any;
        type?: ElementType | undefined;
        width: number;
        height: number;
        rotation: number;
        top: number;
        left: number;
        image: {
          __typename?: 'ElementImage';
          blobID: any;
          url: string;
          aspectRatio: number;
          height: number;
          width: number;
        };
      }
    | {
        __typename?: 'BookElementText';
        id: any;
        type?: ElementType | undefined;
        width: number;
        height: number;
        rotation: number;
        top: number;
        left: number;
        content: string;
        placeholder: string;
        color: string;
        fontFamily: string;
        fontSize: number;
        fontWeight: number;
        fontStyle: string;
        textDecoration: string;
        textAlign: string;
      }
  >;
  background?:
    | {
        __typename?: 'SpreadBackground';
        left?:
          | {
              __typename?: 'PageBackground';
              color?: string | undefined;
              image?:
                | { __typename?: 'BookImage'; url: string; blobID: any }
                | undefined;
            }
          | undefined;
        right?:
          | {
              __typename?: 'PageBackground';
              color?: string | undefined;
              image?:
                | { __typename?: 'BookImage'; url: string; blobID: any }
                | undefined;
            }
          | undefined;
      }
    | undefined;
};

export type GetProductBySlugQueryVariables = Exact<{
  slug: Scalars['String'];
}>;

export type GetProductBySlugQuery = {
  __typename?: 'Query';
  product: {
    __typename?: 'Product';
    id: any;
    bookID: any;
    slug: string;
    title: string;
    description?: string | undefined;
    image?: string | undefined;
    introTitle?: string | undefined;
    introImage?: string | undefined;
    heroImage?: string | undefined;
    exampleBookLargeMobile?: string | undefined;
    exampleBookLarge?: string | undefined;
    exampleBookMedium?: string | undefined;
    hideCTA: boolean;
    images: Array<string>;
    seo: { __typename?: 'SEO'; title: string; description: string };
  };
};

export type GetCollaborativeSpreadsQueryVariables = Exact<{
  bookId: Scalars['UUID'];
}>;

export type GetCollaborativeSpreadsQuery = {
  __typename?: 'Query';
  book: {
    __typename?: 'Book';
    spreads: Array<{
      __typename?: 'BookSpread';
      id: any;
      isCollaborative: boolean;
    }>;
  };
};

export type GetProductsFiltersQueryVariables = Exact<{ [key: string]: never }>;

export type GetProductsFiltersQuery = {
  __typename?: 'Query';
  productFilterCategories: Array<{
    __typename?: 'ProductFilterCategory';
    name: string;
    filters: Array<{ __typename?: 'ProductFilter'; id: any; value: string }>;
  }>;
};

export type GetAllProductsQueryVariables = Exact<{
  filters?: InputMaybe<Array<Scalars['UUID']> | Scalars['UUID']>;
}>;

export type GetAllProductsQuery = {
  __typename?: 'Query';
  products: Array<{
    __typename?: 'Product';
    id: any;
    bookID: any;
    slug: string;
    title: string;
    image?: string | undefined;
    promoTitle?: string | undefined;
    promoColor?: string | undefined;
    promoImage?: string | undefined;
    introText?: string | undefined;
    filters: Array<{
      __typename?: 'ProductFilter';
      id: any;
      value: string;
      category: string;
    }>;
  }>;
};

export type GetFeaturedQueryVariables = Exact<{ [key: string]: never }>;

export type GetFeaturedQuery = {
  __typename?: 'Query';
  featuredProducts: Array<{
    __typename?: 'Product';
    id: any;
    bookID: any;
    slug: string;
    title: string;
    promoTitle?: string | undefined;
    promoColor?: string | undefined;
    promoImage?: string | undefined;
  }>;
};

export type GetBooksPageQueryVariables = Exact<{ [key: string]: never }>;

export type GetBooksPageQuery = {
  __typename?: 'Query';
  books: Array<{
    __typename?: 'Book';
    id: any;
    title: string;
    modifiedAt: any;
    owner?: { __typename?: 'Account'; id: any } | undefined;
  }>;
};

export type GetProductsPageQueryVariables = Exact<{ [key: string]: never }>;

export type GetProductsPageQuery = {
  __typename?: 'Query';
  adminProducts: Array<{
    __typename?: 'Product';
    id: any;
    bookID: any;
    title: string;
  }>;
};

export type GetAllStickersPageQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllStickersPageQuery = {
  __typename?: 'Query';
  stickers: Array<{
    __typename?: 'Image';
    url: string;
    status: ImageStatus;
    blobID: any;
    originalName: string;
  }>;
};

export type GetAllFramesPageQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllFramesPageQuery = {
  __typename?: 'Query';
  frames: Array<{
    __typename?: 'Image';
    url: string;
    status: ImageStatus;
    blobID: any;
    originalName: string;
  }>;
};

export type GetAllBackgroundsPageQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetAllBackgroundsPageQuery = {
  __typename?: 'Query';
  backgrounds: Array<{
    __typename?: 'Image';
    url: string;
    status: ImageStatus;
    blobID: any;
    originalName: string;
  }>;
};

export type GetAccountPageQueryVariables = Exact<{ [key: string]: never }>;

export type GetAccountPageQuery = {
  __typename?: 'Query';
  account: {
    __typename?: 'Account';
    firstName: string;
    lastName: string;
    email: string;
  };
  orders: Array<{
    __typename?: 'Order';
    productImage: string;
    title: string;
    size: BookSize;
    status: OrderStatus;
  }>;
};

export type GetAccountDetailsPageQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetAccountDetailsPageQuery = {
  __typename?: 'Query';
  account: {
    __typename?: 'Account';
    firstName: string;
    lastName: string;
    email: string;
  };
};

export type GetAccountPersonalPageQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetAccountPersonalPageQuery = {
  __typename?: 'Query';
  account: {
    __typename?: 'Account';
    firstName: string;
    lastName: string;
    email: string;
    acceptsMarketing: boolean;
  };
};

export type AccountUpdateMutationVariables = Exact<{
  input: AccountInput;
}>;

export type AccountUpdateMutation = {
  __typename?: 'Mutation';
  accountUpdate: {
    __typename?: 'Account';
    firstName: string;
    lastName: string;
    email: string;
    acceptsMarketing: boolean;
  };
};

export type GetAccountAddressesPageQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetAccountAddressesPageQuery = {
  __typename?: 'Query';
  account: { __typename?: 'Account'; firstName: string; lastName: string };
};

export type GetOrdersPageQueryVariables = Exact<{ [key: string]: never }>;

export type GetOrdersPageQuery = {
  __typename?: 'Query';
  orders: Array<{
    __typename?: 'Order';
    orderNumber: number;
    title: string;
    id: any;
    productImage: string;
    status: OrderStatus;
    createdAt: any;
  }>;
};

export type GetOrderPageQueryVariables = Exact<{
  orderID: Scalars['UUID'];
}>;

export type GetOrderPageQuery = {
  __typename?: 'Query';
  order: {
    __typename?: 'Order';
    paper: BookPaper;
    pageCount: number;
    productImage: string;
    title: string;
    orderNumber: number;
    priceTotal: string;
    firstName: string;
    lastName: string;
    createdAt: any;
    status: OrderStatus;
    size: BookSize;
    cover: BookCover;
    quantity: number;
    priceShipping: string;
    shippingAddress: {
      __typename?: 'OrderAddress';
      firstName: string;
      lastName: string;
      address: string;
      city: string;
      zip: string;
      country: string;
    };
    billingAddress: {
      __typename?: 'OrderAddress';
      firstName: string;
      lastName: string;
      address: string;
      city: string;
      zip: string;
      country: string;
    };
  };
};

export type PasswordChangeMutationVariables = Exact<{
  password: Scalars['String'];
}>;

export type PasswordChangeMutation = {
  __typename?: 'Mutation';
  passwordChange: { __typename?: 'Tokens'; accessToken: string };
};

export type GetBookMembersQueryVariables = Exact<{
  bookId: Scalars['UUID'];
}>;

export type GetBookMembersQuery = {
  __typename?: 'Query';
  bookMembers: Array<{
    __typename?: 'BookMember';
    id: any;
    firstName: string;
    lastName: string;
    email: string;
    role: MemberRole;
    status: MemberStatus;
  }>;
};

export type OnSpreadReorderSubscriptionVariables = Exact<{
  bookID: Scalars['UUID'];
}>;

export type OnSpreadReorderSubscription = {
  __typename?: 'Subscription';
  spreadReorder: {
    __typename?: 'ReorderSpreadMessage';
    spreadID: any;
    sourceIndex: number;
    destinationIndex: number;
  };
};

export type OnSpreadRemoveSubscriptionVariables = Exact<{
  bookID: Scalars['UUID'];
}>;

export type OnSpreadRemoveSubscription = {
  __typename?: 'Subscription';
  spreadRemove: { __typename?: 'RemoveSpreadMessage'; id: any };
};

export type OnElementUpdateSubscriptionVariables = Exact<{
  bookID: Scalars['UUID'];
}>;

export type OnElementUpdateSubscription = {
  __typename?: 'Subscription';
  updateElement: {
    __typename?: 'UpdateElementMessage';
    id: any;
    spreadID: any;
  };
};

export type OnSpreadAddSubscriptionVariables = Exact<{
  bookID: Scalars['UUID'];
}>;

export type OnSpreadAddSubscription = {
  __typename?: 'Subscription';
  spreadAdd: {
    __typename?: 'AddSpreadMessage';
    id: any;
    destinationIndex: number;
  };
};

export type OnElementAddSubscriptionVariables = Exact<{
  bookID: Scalars['UUID'];
}>;

export type OnElementAddSubscription = {
  __typename?: 'Subscription';
  addElement: { __typename?: 'AddElementMessage'; id: any; spreadID: any };
};

export type OnElementReorderSubscriptionVariables = Exact<{
  bookID: Scalars['UUID'];
}>;

export type OnElementReorderSubscription = {
  __typename?: 'Subscription';
  reorderElement: {
    __typename?: 'ReorderElementMessage';
    spreadID: any;
    sourceIndex: number;
    destinationIndex: number;
  };
};

export type OnElementRemoveSubscriptionVariables = Exact<{
  bookID: Scalars['UUID'];
}>;

export type OnElementRemoveSubscription = {
  __typename?: 'Subscription';
  removeElement: {
    __typename?: 'RemoveElementMessage';
    id: any;
    spreadID: any;
  };
};

export type OnElementUndoSubscriptionVariables = Exact<{
  bookID: Scalars['UUID'];
}>;

export type OnElementUndoSubscription = {
  __typename?: 'Subscription';
  undoElement: { __typename?: 'UndoElementMessage'; id: any; spreadID: any };
};

export type OnSpreadBackgroundUpdateSubscriptionVariables = Exact<{
  bookID: Scalars['UUID'];
}>;

export type OnSpreadBackgroundUpdateSubscription = {
  __typename?: 'Subscription';
  spreadBackgroundUpdate: {
    __typename?: 'UpdateSpreadBackgroundMessage';
    spreadID: any;
  };
};

export type OnSpreadThumbnailUpdateSubscriptionVariables = Exact<{
  bookID: Scalars['UUID'];
}>;

export type OnSpreadThumbnailUpdateSubscription = {
  __typename?: 'Subscription';
  spreadThumbnailUpdate: {
    __typename?: 'UpdateSpreadThumbnailMessage';
    spreadID: any;
    thumbnailURL: string;
  };
};

export type OnSpineUpdateSubscriptionVariables = Exact<{
  bookID: Scalars['UUID'];
}>;

export type OnSpineUpdateSubscription = {
  __typename?: 'Subscription';
  spineUpdate: { __typename?: 'UpdateSpineMessage'; bookID: any };
};

export async function getServerPageAdminOrders(
  options: Omit<Apollo.QueryOptions<AdminOrdersQueryVariables>, 'query'>,
  ctx: ApolloClientContext,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<AdminOrdersQuery>({
    ...options,
    query: Operations.AdminOrdersDocument,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      apolloState: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useAdminOrders = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<AdminOrdersQuery, AdminOrdersQueryVariables>,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.AdminOrdersDocument, options);
};
export type PageAdminOrdersComp = React.FC<{
  data?: AdminOrdersQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageAdminOrders =
  (
    optionsFunc?: (
      router: NextRouter,
    ) => QueryHookOptions<AdminOrdersQuery, AdminOrdersQueryVariables>,
  ) =>
  (WrappedComponent: PageAdminOrdersComp): NextPage =>
  (props) => {
    const router = useRouter();
    const options = optionsFunc ? optionsFunc(router) : {};
    const { data, error } = useQuery(Operations.AdminOrdersDocument, options);
    return <WrappedComponent {...props} data={data} error={error} />;
  };
export const ssrAdminOrders = {
  getServerPage: getServerPageAdminOrders,
  withPage: withPageAdminOrders,
  usePage: useAdminOrders,
};

export async function getServerPageGetAccountInfo(
  options: Omit<Apollo.QueryOptions<GetAccountInfoQueryVariables>, 'query'>,
  ctx: ApolloClientContext,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<GetAccountInfoQuery>({
    ...options,
    query: Operations.GetAccountInfoDocument,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      apolloState: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetAccountInfo = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<GetAccountInfoQuery, GetAccountInfoQueryVariables>,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetAccountInfoDocument, options);
};
export type PageGetAccountInfoComp = React.FC<{
  data?: GetAccountInfoQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetAccountInfo =
  (
    optionsFunc?: (
      router: NextRouter,
    ) => QueryHookOptions<GetAccountInfoQuery, GetAccountInfoQueryVariables>,
  ) =>
  (WrappedComponent: PageGetAccountInfoComp): NextPage =>
  (props) => {
    const router = useRouter();
    const options = optionsFunc ? optionsFunc(router) : {};
    const { data, error } = useQuery(
      Operations.GetAccountInfoDocument,
      options,
    );
    return <WrappedComponent {...props} data={data} error={error} />;
  };
export const ssrGetAccountInfo = {
  getServerPage: getServerPageGetAccountInfo,
  withPage: withPageGetAccountInfo,
  usePage: useGetAccountInfo,
};

export async function getServerPageGetPrintSpread(
  options: Omit<Apollo.QueryOptions<GetPrintSpreadQueryVariables>, 'query'>,
  ctx: ApolloClientContext,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<GetPrintSpreadQuery>({
    ...options,
    query: Operations.GetPrintSpreadDocument,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      apolloState: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetPrintSpread = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<GetPrintSpreadQuery, GetPrintSpreadQueryVariables>,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetPrintSpreadDocument, options);
};
export type PageGetPrintSpreadComp = React.FC<{
  data?: GetPrintSpreadQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetPrintSpread =
  (
    optionsFunc?: (
      router: NextRouter,
    ) => QueryHookOptions<GetPrintSpreadQuery, GetPrintSpreadQueryVariables>,
  ) =>
  (WrappedComponent: PageGetPrintSpreadComp): NextPage =>
  (props) => {
    const router = useRouter();
    const options = optionsFunc ? optionsFunc(router) : {};
    const { data, error } = useQuery(
      Operations.GetPrintSpreadDocument,
      options,
    );
    return <WrappedComponent {...props} data={data} error={error} />;
  };
export const ssrGetPrintSpread = {
  getServerPage: getServerPageGetPrintSpread,
  withPage: withPageGetPrintSpread,
  usePage: useGetPrintSpread,
};
export async function getServerPageGetPrintSpine(
  options: Omit<Apollo.QueryOptions<GetPrintSpineQueryVariables>, 'query'>,
  ctx: ApolloClientContext,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<GetPrintSpineQuery>({
    ...options,
    query: Operations.GetPrintSpineDocument,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      apolloState: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetPrintSpine = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<GetPrintSpineQuery, GetPrintSpineQueryVariables>,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetPrintSpineDocument, options);
};
export type PageGetPrintSpineComp = React.FC<{
  data?: GetPrintSpineQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetPrintSpine =
  (
    optionsFunc?: (
      router: NextRouter,
    ) => QueryHookOptions<GetPrintSpineQuery, GetPrintSpineQueryVariables>,
  ) =>
  (WrappedComponent: PageGetPrintSpineComp): NextPage =>
  (props) => {
    const router = useRouter();
    const options = optionsFunc ? optionsFunc(router) : {};
    const { data, error } = useQuery(Operations.GetPrintSpineDocument, options);
    return <WrappedComponent {...props} data={data} error={error} />;
  };
export const ssrGetPrintSpine = {
  getServerPage: getServerPageGetPrintSpine,
  withPage: withPageGetPrintSpine,
  usePage: useGetPrintSpine,
};
export async function getServerPageCalculatePrice(
  options: Omit<Apollo.QueryOptions<CalculatePriceQueryVariables>, 'query'>,
  ctx: ApolloClientContext,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<CalculatePriceQuery>({
    ...options,
    query: Operations.CalculatePriceDocument,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      apolloState: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useCalculatePrice = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<CalculatePriceQuery, CalculatePriceQueryVariables>,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.CalculatePriceDocument, options);
};
export type PageCalculatePriceComp = React.FC<{
  data?: CalculatePriceQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageCalculatePrice =
  (
    optionsFunc?: (
      router: NextRouter,
    ) => QueryHookOptions<CalculatePriceQuery, CalculatePriceQueryVariables>,
  ) =>
  (WrappedComponent: PageCalculatePriceComp): NextPage =>
  (props) => {
    const router = useRouter();
    const options = optionsFunc ? optionsFunc(router) : {};
    const { data, error } = useQuery(
      Operations.CalculatePriceDocument,
      options,
    );
    return <WrappedComponent {...props} data={data} error={error} />;
  };
export const ssrCalculatePrice = {
  getServerPage: getServerPageCalculatePrice,
  withPage: withPageCalculatePrice,
  usePage: useCalculatePrice,
};

export async function getServerPageGetBookById(
  options: Omit<Apollo.QueryOptions<GetBookByIdQueryVariables>, 'query'>,
  ctx: ApolloClientContext,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<GetBookByIdQuery>({
    ...options,
    query: Operations.GetBookByIdDocument,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      apolloState: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetBookById = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<GetBookByIdQuery, GetBookByIdQueryVariables>,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetBookByIdDocument, options);
};
export type PageGetBookByIdComp = React.FC<{
  data?: GetBookByIdQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetBookById =
  (
    optionsFunc?: (
      router: NextRouter,
    ) => QueryHookOptions<GetBookByIdQuery, GetBookByIdQueryVariables>,
  ) =>
  (WrappedComponent: PageGetBookByIdComp): NextPage =>
  (props) => {
    const router = useRouter();
    const options = optionsFunc ? optionsFunc(router) : {};
    const { data, error } = useQuery(Operations.GetBookByIdDocument, options);
    return <WrappedComponent {...props} data={data} error={error} />;
  };
export const ssrGetBookById = {
  getServerPage: getServerPageGetBookById,
  withPage: withPageGetBookById,
  usePage: useGetBookById,
};
export async function getServerPageGetSpine(
  options: Omit<Apollo.QueryOptions<GetSpineQueryVariables>, 'query'>,
  ctx: ApolloClientContext,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<GetSpineQuery>({
    ...options,
    query: Operations.GetSpineDocument,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      apolloState: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetSpine = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<GetSpineQuery, GetSpineQueryVariables>,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetSpineDocument, options);
};
export type PageGetSpineComp = React.FC<{
  data?: GetSpineQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetSpine =
  (
    optionsFunc?: (
      router: NextRouter,
    ) => QueryHookOptions<GetSpineQuery, GetSpineQueryVariables>,
  ) =>
  (WrappedComponent: PageGetSpineComp): NextPage =>
  (props) => {
    const router = useRouter();
    const options = optionsFunc ? optionsFunc(router) : {};
    const { data, error } = useQuery(Operations.GetSpineDocument, options);
    return <WrappedComponent {...props} data={data} error={error} />;
  };
export const ssrGetSpine = {
  getServerPage: getServerPageGetSpine,
  withPage: withPageGetSpine,
  usePage: useGetSpine,
};
export async function getServerPageGetSpreadById(
  options: Omit<Apollo.QueryOptions<GetSpreadByIdQueryVariables>, 'query'>,
  ctx: ApolloClientContext,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<GetSpreadByIdQuery>({
    ...options,
    query: Operations.GetSpreadByIdDocument,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      apolloState: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetSpreadById = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<GetSpreadByIdQuery, GetSpreadByIdQueryVariables>,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetSpreadByIdDocument, options);
};
export type PageGetSpreadByIdComp = React.FC<{
  data?: GetSpreadByIdQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetSpreadById =
  (
    optionsFunc?: (
      router: NextRouter,
    ) => QueryHookOptions<GetSpreadByIdQuery, GetSpreadByIdQueryVariables>,
  ) =>
  (WrappedComponent: PageGetSpreadByIdComp): NextPage =>
  (props) => {
    const router = useRouter();
    const options = optionsFunc ? optionsFunc(router) : {};
    const { data, error } = useQuery(Operations.GetSpreadByIdDocument, options);
    return <WrappedComponent {...props} data={data} error={error} />;
  };
export const ssrGetSpreadById = {
  getServerPage: getServerPageGetSpreadById,
  withPage: withPageGetSpreadById,
  usePage: useGetSpreadById,
};
export async function getServerPageGetCustomImages(
  options: Omit<Apollo.QueryOptions<GetCustomImagesQueryVariables>, 'query'>,
  ctx: ApolloClientContext,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<GetCustomImagesQuery>({
    ...options,
    query: Operations.GetCustomImagesDocument,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      apolloState: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetCustomImages = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<GetCustomImagesQuery, GetCustomImagesQueryVariables>,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetCustomImagesDocument, options);
};
export type PageGetCustomImagesComp = React.FC<{
  data?: GetCustomImagesQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetCustomImages =
  (
    optionsFunc?: (
      router: NextRouter,
    ) => QueryHookOptions<GetCustomImagesQuery, GetCustomImagesQueryVariables>,
  ) =>
  (WrappedComponent: PageGetCustomImagesComp): NextPage =>
  (props) => {
    const router = useRouter();
    const options = optionsFunc ? optionsFunc(router) : {};
    const { data, error } = useQuery(
      Operations.GetCustomImagesDocument,
      options,
    );
    return <WrappedComponent {...props} data={data} error={error} />;
  };
export const ssrGetCustomImages = {
  getServerPage: getServerPageGetCustomImages,
  withPage: withPageGetCustomImages,
  usePage: useGetCustomImages,
};
export async function getServerPageGetMedia(
  options: Omit<Apollo.QueryOptions<GetMediaQueryVariables>, 'query'>,
  ctx: ApolloClientContext,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<GetMediaQuery>({
    ...options,
    query: Operations.GetMediaDocument,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      apolloState: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetMedia = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<GetMediaQuery, GetMediaQueryVariables>,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetMediaDocument, options);
};
export type PageGetMediaComp = React.FC<{
  data?: GetMediaQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetMedia =
  (
    optionsFunc?: (
      router: NextRouter,
    ) => QueryHookOptions<GetMediaQuery, GetMediaQueryVariables>,
  ) =>
  (WrappedComponent: PageGetMediaComp): NextPage =>
  (props) => {
    const router = useRouter();
    const options = optionsFunc ? optionsFunc(router) : {};
    const { data, error } = useQuery(Operations.GetMediaDocument, options);
    return <WrappedComponent {...props} data={data} error={error} />;
  };
export const ssrGetMedia = {
  getServerPage: getServerPageGetMedia,
  withPage: withPageGetMedia,
  usePage: useGetMedia,
};

export async function getServerPageGetBookInvitationToken(
  options: Omit<
    Apollo.QueryOptions<GetBookInvitationTokenQueryVariables>,
    'query'
  >,
  ctx: ApolloClientContext,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<GetBookInvitationTokenQuery>({
    ...options,
    query: Operations.GetBookInvitationTokenDocument,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      apolloState: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetBookInvitationToken = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    GetBookInvitationTokenQuery,
    GetBookInvitationTokenQueryVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetBookInvitationTokenDocument, options);
};
export type PageGetBookInvitationTokenComp = React.FC<{
  data?: GetBookInvitationTokenQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetBookInvitationToken =
  (
    optionsFunc?: (
      router: NextRouter,
    ) => QueryHookOptions<
      GetBookInvitationTokenQuery,
      GetBookInvitationTokenQueryVariables
    >,
  ) =>
  (WrappedComponent: PageGetBookInvitationTokenComp): NextPage =>
  (props) => {
    const router = useRouter();
    const options = optionsFunc ? optionsFunc(router) : {};
    const { data, error } = useQuery(
      Operations.GetBookInvitationTokenDocument,
      options,
    );
    return <WrappedComponent {...props} data={data} error={error} />;
  };
export const ssrGetBookInvitationToken = {
  getServerPage: getServerPageGetBookInvitationToken,
  withPage: withPageGetBookInvitationToken,
  usePage: useGetBookInvitationToken,
};

export async function getServerPageGetProductBySlug(
  options: Omit<Apollo.QueryOptions<GetProductBySlugQueryVariables>, 'query'>,
  ctx: ApolloClientContext,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<GetProductBySlugQuery>({
    ...options,
    query: Operations.GetProductBySlugDocument,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      apolloState: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetProductBySlug = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<GetProductBySlugQuery, GetProductBySlugQueryVariables>,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetProductBySlugDocument, options);
};
export type PageGetProductBySlugComp = React.FC<{
  data?: GetProductBySlugQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetProductBySlug =
  (
    optionsFunc?: (
      router: NextRouter,
    ) => QueryHookOptions<
      GetProductBySlugQuery,
      GetProductBySlugQueryVariables
    >,
  ) =>
  (WrappedComponent: PageGetProductBySlugComp): NextPage =>
  (props) => {
    const router = useRouter();
    const options = optionsFunc ? optionsFunc(router) : {};
    const { data, error } = useQuery(
      Operations.GetProductBySlugDocument,
      options,
    );
    return <WrappedComponent {...props} data={data} error={error} />;
  };
export const ssrGetProductBySlug = {
  getServerPage: getServerPageGetProductBySlug,
  withPage: withPageGetProductBySlug,
  usePage: useGetProductBySlug,
};
export async function getServerPageGetCollaborativeSpreads(
  options: Omit<
    Apollo.QueryOptions<GetCollaborativeSpreadsQueryVariables>,
    'query'
  >,
  ctx: ApolloClientContext,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<GetCollaborativeSpreadsQuery>({
    ...options,
    query: Operations.GetCollaborativeSpreadsDocument,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      apolloState: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetCollaborativeSpreads = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    GetCollaborativeSpreadsQuery,
    GetCollaborativeSpreadsQueryVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetCollaborativeSpreadsDocument, options);
};
export type PageGetCollaborativeSpreadsComp = React.FC<{
  data?: GetCollaborativeSpreadsQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetCollaborativeSpreads =
  (
    optionsFunc?: (
      router: NextRouter,
    ) => QueryHookOptions<
      GetCollaborativeSpreadsQuery,
      GetCollaborativeSpreadsQueryVariables
    >,
  ) =>
  (WrappedComponent: PageGetCollaborativeSpreadsComp): NextPage =>
  (props) => {
    const router = useRouter();
    const options = optionsFunc ? optionsFunc(router) : {};
    const { data, error } = useQuery(
      Operations.GetCollaborativeSpreadsDocument,
      options,
    );
    return <WrappedComponent {...props} data={data} error={error} />;
  };
export const ssrGetCollaborativeSpreads = {
  getServerPage: getServerPageGetCollaborativeSpreads,
  withPage: withPageGetCollaborativeSpreads,
  usePage: useGetCollaborativeSpreads,
};
export async function getServerPageGetProductsFilters(
  options: Omit<Apollo.QueryOptions<GetProductsFiltersQueryVariables>, 'query'>,
  ctx: ApolloClientContext,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<GetProductsFiltersQuery>({
    ...options,
    query: Operations.GetProductsFiltersDocument,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      apolloState: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetProductsFilters = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    GetProductsFiltersQuery,
    GetProductsFiltersQueryVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetProductsFiltersDocument, options);
};
export type PageGetProductsFiltersComp = React.FC<{
  data?: GetProductsFiltersQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetProductsFilters =
  (
    optionsFunc?: (
      router: NextRouter,
    ) => QueryHookOptions<
      GetProductsFiltersQuery,
      GetProductsFiltersQueryVariables
    >,
  ) =>
  (WrappedComponent: PageGetProductsFiltersComp): NextPage =>
  (props) => {
    const router = useRouter();
    const options = optionsFunc ? optionsFunc(router) : {};
    const { data, error } = useQuery(
      Operations.GetProductsFiltersDocument,
      options,
    );
    return <WrappedComponent {...props} data={data} error={error} />;
  };
export const ssrGetProductsFilters = {
  getServerPage: getServerPageGetProductsFilters,
  withPage: withPageGetProductsFilters,
  usePage: useGetProductsFilters,
};
export async function getServerPageGetAllProducts(
  options: Omit<Apollo.QueryOptions<GetAllProductsQueryVariables>, 'query'>,
  ctx: ApolloClientContext,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<GetAllProductsQuery>({
    ...options,
    query: Operations.GetAllProductsDocument,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      apolloState: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetAllProducts = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<GetAllProductsQuery, GetAllProductsQueryVariables>,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetAllProductsDocument, options);
};
export type PageGetAllProductsComp = React.FC<{
  data?: GetAllProductsQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetAllProducts =
  (
    optionsFunc?: (
      router: NextRouter,
    ) => QueryHookOptions<GetAllProductsQuery, GetAllProductsQueryVariables>,
  ) =>
  (WrappedComponent: PageGetAllProductsComp): NextPage =>
  (props) => {
    const router = useRouter();
    const options = optionsFunc ? optionsFunc(router) : {};
    const { data, error } = useQuery(
      Operations.GetAllProductsDocument,
      options,
    );
    return <WrappedComponent {...props} data={data} error={error} />;
  };
export const ssrGetAllProducts = {
  getServerPage: getServerPageGetAllProducts,
  withPage: withPageGetAllProducts,
  usePage: useGetAllProducts,
};
export async function getServerPageGetFeatured(
  options: Omit<Apollo.QueryOptions<GetFeaturedQueryVariables>, 'query'>,
  ctx: ApolloClientContext,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<GetFeaturedQuery>({
    ...options,
    query: Operations.GetFeaturedDocument,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      apolloState: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetFeatured = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<GetFeaturedQuery, GetFeaturedQueryVariables>,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetFeaturedDocument, options);
};
export type PageGetFeaturedComp = React.FC<{
  data?: GetFeaturedQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetFeatured =
  (
    optionsFunc?: (
      router: NextRouter,
    ) => QueryHookOptions<GetFeaturedQuery, GetFeaturedQueryVariables>,
  ) =>
  (WrappedComponent: PageGetFeaturedComp): NextPage =>
  (props) => {
    const router = useRouter();
    const options = optionsFunc ? optionsFunc(router) : {};
    const { data, error } = useQuery(Operations.GetFeaturedDocument, options);
    return <WrappedComponent {...props} data={data} error={error} />;
  };
export const ssrGetFeatured = {
  getServerPage: getServerPageGetFeatured,
  withPage: withPageGetFeatured,
  usePage: useGetFeatured,
};
export async function getServerPageGetBooks(
  options: Omit<Apollo.QueryOptions<GetBooksPageQueryVariables>, 'query'>,
  ctx: ApolloClientContext,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<GetBooksPageQuery>({
    ...options,
    query: Operations.GetBooksPageDocument,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      apolloState: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetBooks = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<GetBooksPageQuery, GetBooksPageQueryVariables>,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetBooksPageDocument, options);
};
export type PageGetBooksComp = React.FC<{
  data?: GetBooksPageQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetBooks =
  (
    optionsFunc?: (
      router: NextRouter,
    ) => QueryHookOptions<GetBooksPageQuery, GetBooksPageQueryVariables>,
  ) =>
  (WrappedComponent: PageGetBooksComp): NextPage =>
  (props) => {
    const router = useRouter();
    const options = optionsFunc ? optionsFunc(router) : {};
    const { data, error } = useQuery(Operations.GetBooksPageDocument, options);
    return <WrappedComponent {...props} data={data} error={error} />;
  };
export const ssrGetBooks = {
  getServerPage: getServerPageGetBooks,
  withPage: withPageGetBooks,
  usePage: useGetBooks,
};
export async function getServerPageGetProducts(
  options: Omit<Apollo.QueryOptions<GetProductsPageQueryVariables>, 'query'>,
  ctx: ApolloClientContext,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<GetProductsPageQuery>({
    ...options,
    query: Operations.GetProductsPageDocument,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      apolloState: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetProducts = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<GetProductsPageQuery, GetProductsPageQueryVariables>,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetProductsPageDocument, options);
};
export type PageGetProductsComp = React.FC<{
  data?: GetProductsPageQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetProducts =
  (
    optionsFunc?: (
      router: NextRouter,
    ) => QueryHookOptions<GetProductsPageQuery, GetProductsPageQueryVariables>,
  ) =>
  (WrappedComponent: PageGetProductsComp): NextPage =>
  (props) => {
    const router = useRouter();
    const options = optionsFunc ? optionsFunc(router) : {};
    const { data, error } = useQuery(
      Operations.GetProductsPageDocument,
      options,
    );
    return <WrappedComponent {...props} data={data} error={error} />;
  };
export const ssrGetProducts = {
  getServerPage: getServerPageGetProducts,
  withPage: withPageGetProducts,
  usePage: useGetProducts,
};
export async function getServerPageGetAllStickers(
  options: Omit<Apollo.QueryOptions<GetAllStickersPageQueryVariables>, 'query'>,
  ctx: ApolloClientContext,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<GetAllStickersPageQuery>({
    ...options,
    query: Operations.GetAllStickersPageDocument,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      apolloState: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetAllStickers = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    GetAllStickersPageQuery,
    GetAllStickersPageQueryVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetAllStickersPageDocument, options);
};
export type PageGetAllStickersComp = React.FC<{
  data?: GetAllStickersPageQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetAllStickers =
  (
    optionsFunc?: (
      router: NextRouter,
    ) => QueryHookOptions<
      GetAllStickersPageQuery,
      GetAllStickersPageQueryVariables
    >,
  ) =>
  (WrappedComponent: PageGetAllStickersComp): NextPage =>
  (props) => {
    const router = useRouter();
    const options = optionsFunc ? optionsFunc(router) : {};
    const { data, error } = useQuery(
      Operations.GetAllStickersPageDocument,
      options,
    );
    return <WrappedComponent {...props} data={data} error={error} />;
  };
export const ssrGetAllStickers = {
  getServerPage: getServerPageGetAllStickers,
  withPage: withPageGetAllStickers,
  usePage: useGetAllStickers,
};
export async function getServerPageGetAllFrames(
  options: Omit<Apollo.QueryOptions<GetAllFramesPageQueryVariables>, 'query'>,
  ctx: ApolloClientContext,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<GetAllFramesPageQuery>({
    ...options,
    query: Operations.GetAllFramesPageDocument,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      apolloState: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetAllFrames = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<GetAllFramesPageQuery, GetAllFramesPageQueryVariables>,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetAllFramesPageDocument, options);
};
export type PageGetAllFramesComp = React.FC<{
  data?: GetAllFramesPageQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetAllFrames =
  (
    optionsFunc?: (
      router: NextRouter,
    ) => QueryHookOptions<
      GetAllFramesPageQuery,
      GetAllFramesPageQueryVariables
    >,
  ) =>
  (WrappedComponent: PageGetAllFramesComp): NextPage =>
  (props) => {
    const router = useRouter();
    const options = optionsFunc ? optionsFunc(router) : {};
    const { data, error } = useQuery(
      Operations.GetAllFramesPageDocument,
      options,
    );
    return <WrappedComponent {...props} data={data} error={error} />;
  };
export const ssrGetAllFrames = {
  getServerPage: getServerPageGetAllFrames,
  withPage: withPageGetAllFrames,
  usePage: useGetAllFrames,
};
export async function getServerPageGetAllBackgrounds(
  options: Omit<
    Apollo.QueryOptions<GetAllBackgroundsPageQueryVariables>,
    'query'
  >,
  ctx: ApolloClientContext,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<GetAllBackgroundsPageQuery>({
    ...options,
    query: Operations.GetAllBackgroundsPageDocument,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      apolloState: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetAllBackgrounds = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    GetAllBackgroundsPageQuery,
    GetAllBackgroundsPageQueryVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetAllBackgroundsPageDocument, options);
};
export type PageGetAllBackgroundsComp = React.FC<{
  data?: GetAllBackgroundsPageQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetAllBackgrounds =
  (
    optionsFunc?: (
      router: NextRouter,
    ) => QueryHookOptions<
      GetAllBackgroundsPageQuery,
      GetAllBackgroundsPageQueryVariables
    >,
  ) =>
  (WrappedComponent: PageGetAllBackgroundsComp): NextPage =>
  (props) => {
    const router = useRouter();
    const options = optionsFunc ? optionsFunc(router) : {};
    const { data, error } = useQuery(
      Operations.GetAllBackgroundsPageDocument,
      options,
    );
    return <WrappedComponent {...props} data={data} error={error} />;
  };
export const ssrGetAllBackgrounds = {
  getServerPage: getServerPageGetAllBackgrounds,
  withPage: withPageGetAllBackgrounds,
  usePage: useGetAllBackgrounds,
};
export async function getServerPageGetAccount(
  options: Omit<Apollo.QueryOptions<GetAccountPageQueryVariables>, 'query'>,
  ctx: ApolloClientContext,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<GetAccountPageQuery>({
    ...options,
    query: Operations.GetAccountPageDocument,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      apolloState: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetAccount = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<GetAccountPageQuery, GetAccountPageQueryVariables>,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetAccountPageDocument, options);
};
export type PageGetAccountComp = React.FC<{
  data?: GetAccountPageQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetAccount =
  (
    optionsFunc?: (
      router: NextRouter,
    ) => QueryHookOptions<GetAccountPageQuery, GetAccountPageQueryVariables>,
  ) =>
  (WrappedComponent: PageGetAccountComp): NextPage =>
  (props) => {
    const router = useRouter();
    const options = optionsFunc ? optionsFunc(router) : {};
    const { data, error } = useQuery(
      Operations.GetAccountPageDocument,
      options,
    );
    return <WrappedComponent {...props} data={data} error={error} />;
  };
export const ssrGetAccount = {
  getServerPage: getServerPageGetAccount,
  withPage: withPageGetAccount,
  usePage: useGetAccount,
};
export async function getServerPageGetAccountDetails(
  options: Omit<
    Apollo.QueryOptions<GetAccountDetailsPageQueryVariables>,
    'query'
  >,
  ctx: ApolloClientContext,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<GetAccountDetailsPageQuery>({
    ...options,
    query: Operations.GetAccountDetailsPageDocument,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      apolloState: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetAccountDetails = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    GetAccountDetailsPageQuery,
    GetAccountDetailsPageQueryVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetAccountDetailsPageDocument, options);
};
export type PageGetAccountDetailsComp = React.FC<{
  data?: GetAccountDetailsPageQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetAccountDetails =
  (
    optionsFunc?: (
      router: NextRouter,
    ) => QueryHookOptions<
      GetAccountDetailsPageQuery,
      GetAccountDetailsPageQueryVariables
    >,
  ) =>
  (WrappedComponent: PageGetAccountDetailsComp): NextPage =>
  (props) => {
    const router = useRouter();
    const options = optionsFunc ? optionsFunc(router) : {};
    const { data, error } = useQuery(
      Operations.GetAccountDetailsPageDocument,
      options,
    );
    return <WrappedComponent {...props} data={data} error={error} />;
  };
export const ssrGetAccountDetails = {
  getServerPage: getServerPageGetAccountDetails,
  withPage: withPageGetAccountDetails,
  usePage: useGetAccountDetails,
};
export async function getServerPageGetAccountPersonal(
  options: Omit<
    Apollo.QueryOptions<GetAccountPersonalPageQueryVariables>,
    'query'
  >,
  ctx: ApolloClientContext,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<GetAccountPersonalPageQuery>({
    ...options,
    query: Operations.GetAccountPersonalPageDocument,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      apolloState: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetAccountPersonal = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    GetAccountPersonalPageQuery,
    GetAccountPersonalPageQueryVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetAccountPersonalPageDocument, options);
};
export type PageGetAccountPersonalComp = React.FC<{
  data?: GetAccountPersonalPageQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetAccountPersonal =
  (
    optionsFunc?: (
      router: NextRouter,
    ) => QueryHookOptions<
      GetAccountPersonalPageQuery,
      GetAccountPersonalPageQueryVariables
    >,
  ) =>
  (WrappedComponent: PageGetAccountPersonalComp): NextPage =>
  (props) => {
    const router = useRouter();
    const options = optionsFunc ? optionsFunc(router) : {};
    const { data, error } = useQuery(
      Operations.GetAccountPersonalPageDocument,
      options,
    );
    return <WrappedComponent {...props} data={data} error={error} />;
  };
export const ssrGetAccountPersonal = {
  getServerPage: getServerPageGetAccountPersonal,
  withPage: withPageGetAccountPersonal,
  usePage: useGetAccountPersonal,
};

export async function getServerPageGetAccountAddresses(
  options: Omit<
    Apollo.QueryOptions<GetAccountAddressesPageQueryVariables>,
    'query'
  >,
  ctx: ApolloClientContext,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<GetAccountAddressesPageQuery>({
    ...options,
    query: Operations.GetAccountAddressesPageDocument,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      apolloState: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetAccountAddresses = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    GetAccountAddressesPageQuery,
    GetAccountAddressesPageQueryVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetAccountAddressesPageDocument, options);
};
export type PageGetAccountAddressesComp = React.FC<{
  data?: GetAccountAddressesPageQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetAccountAddresses =
  (
    optionsFunc?: (
      router: NextRouter,
    ) => QueryHookOptions<
      GetAccountAddressesPageQuery,
      GetAccountAddressesPageQueryVariables
    >,
  ) =>
  (WrappedComponent: PageGetAccountAddressesComp): NextPage =>
  (props) => {
    const router = useRouter();
    const options = optionsFunc ? optionsFunc(router) : {};
    const { data, error } = useQuery(
      Operations.GetAccountAddressesPageDocument,
      options,
    );
    return <WrappedComponent {...props} data={data} error={error} />;
  };
export const ssrGetAccountAddresses = {
  getServerPage: getServerPageGetAccountAddresses,
  withPage: withPageGetAccountAddresses,
  usePage: useGetAccountAddresses,
};
export async function getServerPageGetOrders(
  options: Omit<Apollo.QueryOptions<GetOrdersPageQueryVariables>, 'query'>,
  ctx: ApolloClientContext,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<GetOrdersPageQuery>({
    ...options,
    query: Operations.GetOrdersPageDocument,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      apolloState: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetOrders = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<GetOrdersPageQuery, GetOrdersPageQueryVariables>,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetOrdersPageDocument, options);
};
export type PageGetOrdersComp = React.FC<{
  data?: GetOrdersPageQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetOrders =
  (
    optionsFunc?: (
      router: NextRouter,
    ) => QueryHookOptions<GetOrdersPageQuery, GetOrdersPageQueryVariables>,
  ) =>
  (WrappedComponent: PageGetOrdersComp): NextPage =>
  (props) => {
    const router = useRouter();
    const options = optionsFunc ? optionsFunc(router) : {};
    const { data, error } = useQuery(Operations.GetOrdersPageDocument, options);
    return <WrappedComponent {...props} data={data} error={error} />;
  };
export const ssrGetOrders = {
  getServerPage: getServerPageGetOrders,
  withPage: withPageGetOrders,
  usePage: useGetOrders,
};
export async function getServerPageGetOrder(
  options: Omit<Apollo.QueryOptions<GetOrderPageQueryVariables>, 'query'>,
  ctx: ApolloClientContext,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<GetOrderPageQuery>({
    ...options,
    query: Operations.GetOrderPageDocument,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      apolloState: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetOrder = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<GetOrderPageQuery, GetOrderPageQueryVariables>,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetOrderPageDocument, options);
};
export type PageGetOrderComp = React.FC<{
  data?: GetOrderPageQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetOrder =
  (
    optionsFunc?: (
      router: NextRouter,
    ) => QueryHookOptions<GetOrderPageQuery, GetOrderPageQueryVariables>,
  ) =>
  (WrappedComponent: PageGetOrderComp): NextPage =>
  (props) => {
    const router = useRouter();
    const options = optionsFunc ? optionsFunc(router) : {};
    const { data, error } = useQuery(Operations.GetOrderPageDocument, options);
    return <WrappedComponent {...props} data={data} error={error} />;
  };
export const ssrGetOrder = {
  getServerPage: getServerPageGetOrder,
  withPage: withPageGetOrder,
  usePage: useGetOrder,
};

export async function getServerPageGetBookMembers(
  options: Omit<Apollo.QueryOptions<GetBookMembersQueryVariables>, 'query'>,
  ctx: ApolloClientContext,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<GetBookMembersQuery>({
    ...options,
    query: Operations.GetBookMembersDocument,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      apolloState: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetBookMembers = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<GetBookMembersQuery, GetBookMembersQueryVariables>,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetBookMembersDocument, options);
};
export type PageGetBookMembersComp = React.FC<{
  data?: GetBookMembersQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetBookMembers =
  (
    optionsFunc?: (
      router: NextRouter,
    ) => QueryHookOptions<GetBookMembersQuery, GetBookMembersQueryVariables>,
  ) =>
  (WrappedComponent: PageGetBookMembersComp): NextPage =>
  (props) => {
    const router = useRouter();
    const options = optionsFunc ? optionsFunc(router) : {};
    const { data, error } = useQuery(
      Operations.GetBookMembersDocument,
      options,
    );
    return <WrappedComponent {...props} data={data} error={error} />;
  };
export const ssrGetBookMembers = {
  getServerPage: getServerPageGetBookMembers,
  withPage: withPageGetBookMembers,
  usePage: useGetBookMembers,
};
export async function getServerPageOnSpreadReorder(
  options: Omit<
    Apollo.QueryOptions<OnSpreadReorderSubscriptionVariables>,
    'query'
  >,
  ctx: ApolloClientContext,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<OnSpreadReorderSubscription>({
    ...options,
    query: Operations.OnSpreadReorderDocument,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      apolloState: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useOnSpreadReorder = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    OnSpreadReorderSubscription,
    OnSpreadReorderSubscriptionVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.OnSpreadReorderDocument, options);
};
export type PageOnSpreadReorderComp = React.FC<{
  data?: OnSpreadReorderSubscription;
  error?: Apollo.ApolloError;
}>;
export const withPageOnSpreadReorder =
  (
    optionsFunc?: (
      router: NextRouter,
    ) => QueryHookOptions<
      OnSpreadReorderSubscription,
      OnSpreadReorderSubscriptionVariables
    >,
  ) =>
  (WrappedComponent: PageOnSpreadReorderComp): NextPage =>
  (props) => {
    const router = useRouter();
    const options = optionsFunc ? optionsFunc(router) : {};
    const { data, error } = useQuery(
      Operations.OnSpreadReorderDocument,
      options,
    );
    return <WrappedComponent {...props} data={data} error={error} />;
  };
export const ssrOnSpreadReorder = {
  getServerPage: getServerPageOnSpreadReorder,
  withPage: withPageOnSpreadReorder,
  usePage: useOnSpreadReorder,
};
export async function getServerPageOnSpreadRemove(
  options: Omit<
    Apollo.QueryOptions<OnSpreadRemoveSubscriptionVariables>,
    'query'
  >,
  ctx: ApolloClientContext,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<OnSpreadRemoveSubscription>({
    ...options,
    query: Operations.OnSpreadRemoveDocument,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      apolloState: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useOnSpreadRemove = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    OnSpreadRemoveSubscription,
    OnSpreadRemoveSubscriptionVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.OnSpreadRemoveDocument, options);
};
export type PageOnSpreadRemoveComp = React.FC<{
  data?: OnSpreadRemoveSubscription;
  error?: Apollo.ApolloError;
}>;
export const withPageOnSpreadRemove =
  (
    optionsFunc?: (
      router: NextRouter,
    ) => QueryHookOptions<
      OnSpreadRemoveSubscription,
      OnSpreadRemoveSubscriptionVariables
    >,
  ) =>
  (WrappedComponent: PageOnSpreadRemoveComp): NextPage =>
  (props) => {
    const router = useRouter();
    const options = optionsFunc ? optionsFunc(router) : {};
    const { data, error } = useQuery(
      Operations.OnSpreadRemoveDocument,
      options,
    );
    return <WrappedComponent {...props} data={data} error={error} />;
  };
export const ssrOnSpreadRemove = {
  getServerPage: getServerPageOnSpreadRemove,
  withPage: withPageOnSpreadRemove,
  usePage: useOnSpreadRemove,
};
export async function getServerPageOnElementUpdate(
  options: Omit<
    Apollo.QueryOptions<OnElementUpdateSubscriptionVariables>,
    'query'
  >,
  ctx: ApolloClientContext,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<OnElementUpdateSubscription>({
    ...options,
    query: Operations.OnElementUpdateDocument,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      apolloState: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useOnElementUpdate = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    OnElementUpdateSubscription,
    OnElementUpdateSubscriptionVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.OnElementUpdateDocument, options);
};
export type PageOnElementUpdateComp = React.FC<{
  data?: OnElementUpdateSubscription;
  error?: Apollo.ApolloError;
}>;
export const withPageOnElementUpdate =
  (
    optionsFunc?: (
      router: NextRouter,
    ) => QueryHookOptions<
      OnElementUpdateSubscription,
      OnElementUpdateSubscriptionVariables
    >,
  ) =>
  (WrappedComponent: PageOnElementUpdateComp): NextPage =>
  (props) => {
    const router = useRouter();
    const options = optionsFunc ? optionsFunc(router) : {};
    const { data, error } = useQuery(
      Operations.OnElementUpdateDocument,
      options,
    );
    return <WrappedComponent {...props} data={data} error={error} />;
  };
export const ssrOnElementUpdate = {
  getServerPage: getServerPageOnElementUpdate,
  withPage: withPageOnElementUpdate,
  usePage: useOnElementUpdate,
};
export async function getServerPageOnSpreadAdd(
  options: Omit<Apollo.QueryOptions<OnSpreadAddSubscriptionVariables>, 'query'>,
  ctx: ApolloClientContext,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<OnSpreadAddSubscription>({
    ...options,
    query: Operations.OnSpreadAddDocument,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      apolloState: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useOnSpreadAdd = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    OnSpreadAddSubscription,
    OnSpreadAddSubscriptionVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.OnSpreadAddDocument, options);
};
export type PageOnSpreadAddComp = React.FC<{
  data?: OnSpreadAddSubscription;
  error?: Apollo.ApolloError;
}>;
export const withPageOnSpreadAdd =
  (
    optionsFunc?: (
      router: NextRouter,
    ) => QueryHookOptions<
      OnSpreadAddSubscription,
      OnSpreadAddSubscriptionVariables
    >,
  ) =>
  (WrappedComponent: PageOnSpreadAddComp): NextPage =>
  (props) => {
    const router = useRouter();
    const options = optionsFunc ? optionsFunc(router) : {};
    const { data, error } = useQuery(Operations.OnSpreadAddDocument, options);
    return <WrappedComponent {...props} data={data} error={error} />;
  };
export const ssrOnSpreadAdd = {
  getServerPage: getServerPageOnSpreadAdd,
  withPage: withPageOnSpreadAdd,
  usePage: useOnSpreadAdd,
};
export async function getServerPageOnElementAdd(
  options: Omit<
    Apollo.QueryOptions<OnElementAddSubscriptionVariables>,
    'query'
  >,
  ctx: ApolloClientContext,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<OnElementAddSubscription>({
    ...options,
    query: Operations.OnElementAddDocument,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      apolloState: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useOnElementAdd = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    OnElementAddSubscription,
    OnElementAddSubscriptionVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.OnElementAddDocument, options);
};
export type PageOnElementAddComp = React.FC<{
  data?: OnElementAddSubscription;
  error?: Apollo.ApolloError;
}>;
export const withPageOnElementAdd =
  (
    optionsFunc?: (
      router: NextRouter,
    ) => QueryHookOptions<
      OnElementAddSubscription,
      OnElementAddSubscriptionVariables
    >,
  ) =>
  (WrappedComponent: PageOnElementAddComp): NextPage =>
  (props) => {
    const router = useRouter();
    const options = optionsFunc ? optionsFunc(router) : {};
    const { data, error } = useQuery(Operations.OnElementAddDocument, options);
    return <WrappedComponent {...props} data={data} error={error} />;
  };
export const ssrOnElementAdd = {
  getServerPage: getServerPageOnElementAdd,
  withPage: withPageOnElementAdd,
  usePage: useOnElementAdd,
};
export async function getServerPageOnElementReorder(
  options: Omit<
    Apollo.QueryOptions<OnElementReorderSubscriptionVariables>,
    'query'
  >,
  ctx: ApolloClientContext,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<OnElementReorderSubscription>({
    ...options,
    query: Operations.OnElementReorderDocument,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      apolloState: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useOnElementReorder = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    OnElementReorderSubscription,
    OnElementReorderSubscriptionVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.OnElementReorderDocument, options);
};
export type PageOnElementReorderComp = React.FC<{
  data?: OnElementReorderSubscription;
  error?: Apollo.ApolloError;
}>;
export const withPageOnElementReorder =
  (
    optionsFunc?: (
      router: NextRouter,
    ) => QueryHookOptions<
      OnElementReorderSubscription,
      OnElementReorderSubscriptionVariables
    >,
  ) =>
  (WrappedComponent: PageOnElementReorderComp): NextPage =>
  (props) => {
    const router = useRouter();
    const options = optionsFunc ? optionsFunc(router) : {};
    const { data, error } = useQuery(
      Operations.OnElementReorderDocument,
      options,
    );
    return <WrappedComponent {...props} data={data} error={error} />;
  };
export const ssrOnElementReorder = {
  getServerPage: getServerPageOnElementReorder,
  withPage: withPageOnElementReorder,
  usePage: useOnElementReorder,
};
export async function getServerPageOnElementRemove(
  options: Omit<
    Apollo.QueryOptions<OnElementRemoveSubscriptionVariables>,
    'query'
  >,
  ctx: ApolloClientContext,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<OnElementRemoveSubscription>({
    ...options,
    query: Operations.OnElementRemoveDocument,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      apolloState: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useOnElementRemove = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    OnElementRemoveSubscription,
    OnElementRemoveSubscriptionVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.OnElementRemoveDocument, options);
};
export type PageOnElementRemoveComp = React.FC<{
  data?: OnElementRemoveSubscription;
  error?: Apollo.ApolloError;
}>;
export const withPageOnElementRemove =
  (
    optionsFunc?: (
      router: NextRouter,
    ) => QueryHookOptions<
      OnElementRemoveSubscription,
      OnElementRemoveSubscriptionVariables
    >,
  ) =>
  (WrappedComponent: PageOnElementRemoveComp): NextPage =>
  (props) => {
    const router = useRouter();
    const options = optionsFunc ? optionsFunc(router) : {};
    const { data, error } = useQuery(
      Operations.OnElementRemoveDocument,
      options,
    );
    return <WrappedComponent {...props} data={data} error={error} />;
  };
export const ssrOnElementRemove = {
  getServerPage: getServerPageOnElementRemove,
  withPage: withPageOnElementRemove,
  usePage: useOnElementRemove,
};
export async function getServerPageOnElementUndo(
  options: Omit<
    Apollo.QueryOptions<OnElementUndoSubscriptionVariables>,
    'query'
  >,
  ctx: ApolloClientContext,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<OnElementUndoSubscription>({
    ...options,
    query: Operations.OnElementUndoDocument,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      apolloState: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useOnElementUndo = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    OnElementUndoSubscription,
    OnElementUndoSubscriptionVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.OnElementUndoDocument, options);
};
export type PageOnElementUndoComp = React.FC<{
  data?: OnElementUndoSubscription;
  error?: Apollo.ApolloError;
}>;
export const withPageOnElementUndo =
  (
    optionsFunc?: (
      router: NextRouter,
    ) => QueryHookOptions<
      OnElementUndoSubscription,
      OnElementUndoSubscriptionVariables
    >,
  ) =>
  (WrappedComponent: PageOnElementUndoComp): NextPage =>
  (props) => {
    const router = useRouter();
    const options = optionsFunc ? optionsFunc(router) : {};
    const { data, error } = useQuery(Operations.OnElementUndoDocument, options);
    return <WrappedComponent {...props} data={data} error={error} />;
  };
export const ssrOnElementUndo = {
  getServerPage: getServerPageOnElementUndo,
  withPage: withPageOnElementUndo,
  usePage: useOnElementUndo,
};
export async function getServerPageOnSpreadBackgroundUpdate(
  options: Omit<
    Apollo.QueryOptions<OnSpreadBackgroundUpdateSubscriptionVariables>,
    'query'
  >,
  ctx: ApolloClientContext,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<OnSpreadBackgroundUpdateSubscription>({
    ...options,
    query: Operations.OnSpreadBackgroundUpdateDocument,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      apolloState: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useOnSpreadBackgroundUpdate = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    OnSpreadBackgroundUpdateSubscription,
    OnSpreadBackgroundUpdateSubscriptionVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.OnSpreadBackgroundUpdateDocument, options);
};
export type PageOnSpreadBackgroundUpdateComp = React.FC<{
  data?: OnSpreadBackgroundUpdateSubscription;
  error?: Apollo.ApolloError;
}>;
export const withPageOnSpreadBackgroundUpdate =
  (
    optionsFunc?: (
      router: NextRouter,
    ) => QueryHookOptions<
      OnSpreadBackgroundUpdateSubscription,
      OnSpreadBackgroundUpdateSubscriptionVariables
    >,
  ) =>
  (WrappedComponent: PageOnSpreadBackgroundUpdateComp): NextPage =>
  (props) => {
    const router = useRouter();
    const options = optionsFunc ? optionsFunc(router) : {};
    const { data, error } = useQuery(
      Operations.OnSpreadBackgroundUpdateDocument,
      options,
    );
    return <WrappedComponent {...props} data={data} error={error} />;
  };
export const ssrOnSpreadBackgroundUpdate = {
  getServerPage: getServerPageOnSpreadBackgroundUpdate,
  withPage: withPageOnSpreadBackgroundUpdate,
  usePage: useOnSpreadBackgroundUpdate,
};
export async function getServerPageOnSpreadThumbnailUpdate(
  options: Omit<
    Apollo.QueryOptions<OnSpreadThumbnailUpdateSubscriptionVariables>,
    'query'
  >,
  ctx: ApolloClientContext,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<OnSpreadThumbnailUpdateSubscription>({
    ...options,
    query: Operations.OnSpreadThumbnailUpdateDocument,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      apolloState: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useOnSpreadThumbnailUpdate = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    OnSpreadThumbnailUpdateSubscription,
    OnSpreadThumbnailUpdateSubscriptionVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.OnSpreadThumbnailUpdateDocument, options);
};
export type PageOnSpreadThumbnailUpdateComp = React.FC<{
  data?: OnSpreadThumbnailUpdateSubscription;
  error?: Apollo.ApolloError;
}>;
export const withPageOnSpreadThumbnailUpdate =
  (
    optionsFunc?: (
      router: NextRouter,
    ) => QueryHookOptions<
      OnSpreadThumbnailUpdateSubscription,
      OnSpreadThumbnailUpdateSubscriptionVariables
    >,
  ) =>
  (WrappedComponent: PageOnSpreadThumbnailUpdateComp): NextPage =>
  (props) => {
    const router = useRouter();
    const options = optionsFunc ? optionsFunc(router) : {};
    const { data, error } = useQuery(
      Operations.OnSpreadThumbnailUpdateDocument,
      options,
    );
    return <WrappedComponent {...props} data={data} error={error} />;
  };
export const ssrOnSpreadThumbnailUpdate = {
  getServerPage: getServerPageOnSpreadThumbnailUpdate,
  withPage: withPageOnSpreadThumbnailUpdate,
  usePage: useOnSpreadThumbnailUpdate,
};
export async function getServerPageOnSpineUpdate(
  options: Omit<
    Apollo.QueryOptions<OnSpineUpdateSubscriptionVariables>,
    'query'
  >,
  ctx: ApolloClientContext,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<OnSpineUpdateSubscription>({
    ...options,
    query: Operations.OnSpineUpdateDocument,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      apolloState: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useOnSpineUpdate = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    OnSpineUpdateSubscription,
    OnSpineUpdateSubscriptionVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.OnSpineUpdateDocument, options);
};
export type PageOnSpineUpdateComp = React.FC<{
  data?: OnSpineUpdateSubscription;
  error?: Apollo.ApolloError;
}>;
export const withPageOnSpineUpdate =
  (
    optionsFunc?: (
      router: NextRouter,
    ) => QueryHookOptions<
      OnSpineUpdateSubscription,
      OnSpineUpdateSubscriptionVariables
    >,
  ) =>
  (WrappedComponent: PageOnSpineUpdateComp): NextPage =>
  (props) => {
    const router = useRouter();
    const options = optionsFunc ? optionsFunc(router) : {};
    const { data, error } = useQuery(Operations.OnSpineUpdateDocument, options);
    return <WrappedComponent {...props} data={data} error={error} />;
  };
export const ssrOnSpineUpdate = {
  getServerPage: getServerPageOnSpineUpdate,
  withPage: withPageOnSpineUpdate,
  usePage: useOnSpineUpdate,
};
